import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { DatacallsService } from 'src/app/datacalls.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { ArrayType } from '@angular/compiler';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IndustriesService } from 'src/app/services/apis/industries.service';
import { Router } from '@angular/router';

export interface DialogData {
  id: ArrayType;
  // name: string;
}

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.scss'],
  providers: [DatacallsService]
})
export class IndustryComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'sub1', 'action'];
  dataSource;
  posts = [];
  industryForm;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(private DatacallsService: DatacallsService, private fb: FormBuilder, private snackBar: MatSnackBar, public dialog: MatDialog, private router: Router, private industriesService: IndustriesService) {



  }

  ngOnInit() {
    this.getIndustries();
  }

  getIndustries() {
    this.industriesService.getIndustries().subscribe((response: any) => {
      console.log('response---->>',response);
      if (response.statusCode == 200) {
        this.posts = response.result;
        this.setDataSource();
      }
      if (response.statusCode == 403){
        this.router.navigate(['/unauthorized']);
      }
    }, (error) => {
      console.log("API Error------------", error);
      this.setDataSource();
    })

  }

  redirectToSymbol(id){
    // console.log('industry id -->>',id);
    this.router.navigate(['/industry-symbol/'+id]);
  }

  setDataSource() {
    console.log("Function called to show the industries");
    this.dataSource = new MatTableDataSource(this.posts);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  editIndustry(industry) {
    industry.isEditable = true;
    industry.previousName = industry.name;
    this.dataSource.data = this.posts;
  }


  saveChanges(industry) {
    this.updateDataSource(industry);
  }

  cancelChanges(industry) {
    industry.name = industry.previousName;
    this.updateDataSource(industry);
  }

  updateDataSource(industry) {
    // Update data source value
    industry.isEditable = false;
    delete industry.previousName;
    console.log(industry, this.posts);
    this.dataSource.data = this.posts;
  }

  deleteIndustryData(id){
    console.log('inside delete industry-->>',id);
    let data={"id":[id]}
    this.industriesService.deleteIndustry(data).subscribe((res: any) => {
      if(res.statusCode==200){
        this.snackBar.open("Industry Deleted", '', {
          duration: 2000
        });
        this.ngOnInit();
      }else{
        this.snackBar.open("Something Went Wrong ", '', {
          duration: 2000
        });
      }
    })
  }



  openDialog(id): void {
    console.log("id ---=-=-=>", id)
    const dialogRef = this.dialog.open(confirmationDialog, {
      // width: '250px',
      data: { "id": id },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.ngOnInit();
    });

  }
  // dialog for viewIndustry Symbol
  openStocksDialog(industry): void {
    console.log("id ---dialog1=Industry-id-=-=>", industry.id)
    const dialogRef = this.dialog.open(viewStockDialog, {
      disableClose: true,
      restoreFocus: false,
      width: "70%",
      autoFocus: false,
      // height: "60%",
      data: { "id": industry.id, "name": industry.name },
    });

    dialogRef.afterClosed().subscribe(result => { 
     
      console.log('The dialog was closed');
      this.ngOnInit();
    });

  }

}


/********************* view stock  Dialog ********************************/

// new component started here 
@Component({
  selector: 'industry',
  templateUrl: 'viewStockDialog.html',
  styleUrls: ['./view-stocks-dialog.scss'],
  providers: [DatacallsService]
})
export class viewStockDialog {

  industry_id = this.data.id;
  industry_name = this.data["name"];
  industrySymbols = [];
  unallocatedSymbols = [];

  constructor(
    public dialogRef: MatDialogRef<confirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private DatacallsService: DatacallsService, private snackBar: MatSnackBar, private FormBuilder: FormBuilder, public dialog: MatDialog, private industryService: IndustriesService
  ) {
    console.log('dialog data is here-->>', this.data);
  }

  ngOnInit() {
    this.getIndustrySymbols()
    this.getUnallocatedSymbols();
  }

  getIndustrySymbols() {
    // Get Industry Symbols
    // this.DatacallsService.getIndustrySymbol(this.data.id).subscribe(symbolsResponse => {
    //   console.log("Symbol Response ----------", symbolsResponse);
    //   if (symbolsResponse.statusCode == 200) {
    //     this.industrySymbols = symbolsResponse.result;
    //   }
    // }, error => {
    //   console.log("API Error -------------", error);
    //   this.snackBar.open("Server is down currently. Contact the developers!", '', {
    //     duration: 2000
    //   });
    // });
  }

  getUnallocatedSymbols() {
    // Get Unallocated Symbols
    // this.DatacallsService.view_industry_symbol(0).subscribe(unallocatedSymbolsResponse => {
    //   console.log("Unallocated Symbol Response ----------", unallocatedSymbolsResponse);
    //   if (unallocatedSymbolsResponse.statusCode == 200) {
    //     this.unallocatedSymbols = unallocatedSymbolsResponse.result;
    //   }
    // }, error => {
    //   console.log("API Error -------------", error);
    //   this.snackBar.open("Server is down currently. Contact the developers!", '', {
    //     duration: 2000
    //   });
    // });
  }
  // onCheckboxChange(e) {
  //   // console.log('selected checkbox------->>',e.target.value);
  //   const symbol_name: FormArray = this.symbolForm.get('symbol_name') as FormArray;

  //   if (e.target.checked) {
  //     symbol_name.push(new FormControl(e.target.value)); // push selected value in array

  //   } else {
  //     const index = symbol_name.controls.findIndex(x => x.value === e.target.value);
  //     symbol_name.removeAt(index);
  //   }
  //   // console.log('selected checkbox---n---->>',symbol_name.value);

  // }


  addSymbolToIndustry(event, symbol) {
    if (event.checked) { // Symbol Needs to be added in the industry
      let industrySymbolParams = [{ "symbol_name": symbol.symbol_name, "industry_id": this.industry_id }];
      this.industryService.addSymbolsToIndustry(industrySymbolParams).subscribe((symbolAddResponse: any) => {
        console.log("Symbol delete response -------", symbolAddResponse);
        if (symbolAddResponse.statusCode == 200) {
          // Symbol Deleted successfully so remove from industry and add to unallocated symbols
          let index = this.unallocatedSymbols.indexOf(symbol);
          this.unallocatedSymbols.splice(index, 1);
          this.industrySymbols.push(symbol);
        } else {
          event.checked = true;
        }
      }, error => {
        console.log("Symbol Add response error--------");
        event.checked = true;
      });
    }

  }


  removeSymbolFromIndustry(event, symbol) {
    let industryDeleteParams = [{
      "symbol_name": symbol.symbol_name, "industry_id": this.industry_id
    }];
    console.log(event.checked);
    if (!event.checked) { // The symbol is removed from the industry
      // Make api call to remove symbol from the industry
      this.industryService.deleteIndustrySymbol(industryDeleteParams).subscribe((symbolDeleteResponse: any) => {
        console.log("Symbol delete response -------", symbolDeleteResponse);
        if (symbolDeleteResponse.statusCode == 200) {
          // Symbol Deleted successfully so remove from industry and add to unallocated symbols
          let index = this.industrySymbols.indexOf(symbol);
          this.industrySymbols.splice(index, 1);
          this.unallocatedSymbols.push(symbol);
        } else {
          event.checked = true;
        }
      }, error => {
        console.log("Symbol delete response error--------");
        event.checked = true;
      })
    }
  }

  dismissModal(): void {
    this.dialogRef.close();
  }

  /* onDelete(id) {
    let ids = [];
    let symbolArr = this.symbolForm.value.symbol_name;
    let dataArr = [];
    console.log("deletion id ------->>", id);
    ids.push(id);
    console.log('selected checkbox---n---->>', this.symbolForm.value.symbol_name);
    symbolArr.forEach((element) => {
      dataArr.push({ "symbol_name": element, "industry_id": this.industry_id });
    })

    let data = {
      "data": dataArr
    }
    console.log('data delete ----->>', data);
    this.DatacallsService.deleteIndustrySymbol(data).subscribe(posts => {
      this.posts = posts;
      console.log('posts------>>', this.posts);
      if (this.posts.statusCode == 200) {
        this.snackBar.open('Data Deleted Successfully', '', {
          duration: 2000
        });
      } else {
        this.snackBar.open('Something Went Wrong.', '', {
          duration: 2000
        });
      }
      this.ngOnInit();
    });

  } */

}


/********************* Delete confirmation Dialog ********************************/

// new component started here 
@Component({
  selector: 'industry',
  templateUrl: 'confirmationDialog.html',
  providers: [DatacallsService]
})
export class confirmationDialog {
  posts;
  dataSource;
  constructor(
    public dialogRef: MatDialogRef<confirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private DatacallsService: DatacallsService, private snackBar: MatSnackBar, public dialog: MatDialog
  ) { }

  ngOnInit() {
    // this.DatacallsService.view_industry_stock(null).subscribe(post => {
    //   this.posts = post.result;
    //   console.log('posts data-=-=->>', this.posts);

    //   this.dataSource = new MatTableDataSource(this.posts);


    // })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDelete(id) {
    let ids = [];
    console.log("deletion id ------->>", id);
    ids.push(id);
    let data = {
      "id": ids
    }
    console.log('data delete ----->>', data);
    // this.DatacallsService.deleteIndustry(data).subscribe(posts => {
    //   this.posts = posts.result;
    //   console.log('posts------>>', this.posts);
    //   this.snackBar.open('Data Deleted Successfully', '', {
    //     duration: 2000
    //   });

    // });

  }

}





