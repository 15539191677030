import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DatacallsService } from '../datacalls.service';
import { DateAdapter, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';

import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
import * as moment from '../utils/moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RmnotesService } from '../services/apis/rmnotes.service';
import { CommonService } from '../services/apis/common.service';


@Component({
  selector: 'app-editor-notes',
  templateUrl: './editor-notes.component.html',
  styleUrls: ['./editor-notes.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [DatacallsService, DatePipe]
})
export class EditorNotesComponent implements OnInit {
  public modeselect = 'daily';
  // selected='option1';
  onChange(val: string) {
    this.modeselect = val;
    console.log('value----------------=>', val);

  }
  tabs = ['DAILY', 'WEEKLY'];
  selected = new FormControl(0);

  columnsToDisplay = ['id', 'cdate','created_on'];
  // displayedColumns = ['id', 'description','created_on','delete_note','edit'];
  // , 'logistic', 'docket', 'edit', 'last_update'
  // filterForm:FormGroup;
  // postsSymbol = [];
  posts = [];
  date;
  symbol;
  data;
  symbolArr;
  dataSource; edit_id; onChangeResult; onChangeResult1;
  // public shipFormat: Object;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  myDate = new Date();
  NotesForm;
  formData;
  // positive;
  // negative;
  i = 0; j = 0; k = 0; l = 0; m = 0; n = 0; o = 0; p = 0; q = 0;
  constructor(private _fb: FormBuilder, public datacalls: DatacallsService, public snackBar: MatSnackBar, private Router: Router, public dialog: MatDialog, @Inject(DOCUMENT) doc: any, private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private commonService: CommonService,private dateAdapter: DateAdapter<Date>,private rmnotesService:RmnotesService) {
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    this.clearAllFields();
  }

  clearAllFields(){
    this.NotesForm = this._fb.group({
      id: [0],
      day_in_nutshell: this._fb.array([]),
      cdate: ['', Validators.required],
      created_on: ['', Validators.required],
      cues_from_nifty_daily: this._fb.array([]),
      positive: this._fb.array([]),
      negative: this._fb.array([]),
      stocks_to_watch: this._fb.array([]),
      last_week_nutshell: this._fb.array([]),
      cues_from_nifty_weekly: this._fb.array([]),
      stocks_on_weekly_radar: this._fb.array([]),
      ranking:  this._fb.array([]),
    })
    this.initGroup(); // for positive 
    this.initGroup1(); // for negative
    this.initGroup2(); // for Day in Nutshell
    this.initGroup3(); // for Cues from nifty daily
    this.initGroup4(); // for stocks_to_watch
    this.initGroup5(); // for last_week_nutshell
    this.initGroup6(); // for cues_from_nifty_weekly
    this.initGroup7(); // for stocks_on_weekly_radar
    this.initGroup8(); // for ranking
  }

  // materials = [{id:1},{id:2},{id:3},{id:4}];
  initGroup() { // positive
    this.i = this.i + 1;
    let positive = this.NotesForm.get('positive') as FormArray;
    positive.push(this._fb.group({
      id: this.i,
      data: ['']
    }))
  }

  initGroup1() {  //negative
    this.j = this.j + 1;
    let negative = this.NotesForm.get('negative') as FormArray;
    negative.push(this._fb.group({
      id: this.j,
      data: ['']
    }))
  }

  initGroup2() { // for day  in Nutshell
    this.k = this.k + 1;
    let day_in_nutshell = this.NotesForm.get('day_in_nutshell') as FormArray;
    day_in_nutshell.push(this._fb.group({
      id: this.k,
      data: ['']
    }))
  }

  initGroup3() { // for cues_from_nifty_daily
    this.l = this.l + 1;
    let cues_from_nifty_daily = this.NotesForm.get('cues_from_nifty_daily') as FormArray;
    cues_from_nifty_daily.push(this._fb.group({
      id: this.l,
      data: ['']
    }))
  }

  initGroup4() { // for stocks_to_watch
    this.m = this.m + 1;
    let stocks_to_watch = this.NotesForm.get('stocks_to_watch') as FormArray;
    stocks_to_watch.push(this._fb.group({
      id: this.m,
      data: ['']
    }))
  }

  initGroup5() { // for last_week_nutshell
    this.n = this.n + 1;
    let last_week_nutshell = this.NotesForm.get('last_week_nutshell') as FormArray;
    last_week_nutshell.push(this._fb.group({
      id: this.n,
      data: ['']
    }))
  }

  initGroup6() { // for cues_from_nifty_weekly
    this.o = this.o + 1;
    let cues_from_nifty_weekly = this.NotesForm.get('cues_from_nifty_weekly') as FormArray;
    cues_from_nifty_weekly.push(this._fb.group({
      id: this.o,
      data: ['']
    }))
  }

  initGroup7() { // for stocks_on_weekly_radar
    this.p = this.p + 1;
    let stocks_on_weekly_radar = this.NotesForm.get('stocks_on_weekly_radar') as FormArray;
    stocks_on_weekly_radar.push(this._fb.group({
      id: this.p,
      data: ['']
    }))
  }

  initGroup8() { // for ranking
    this.q = this.q + 1;
    let ranking = this.NotesForm.get('ranking') as FormArray;
    ranking.push(this._fb.group({
      id: this.q,
      data: ['']
    }))
  }

  onDeleteRow(rowIndex) {   // for positive row deletion
    this.i = this.i - 1;
    let positive = this.NotesForm.get('positive') as FormArray;
    positive.removeAt(rowIndex)
  }

  onDeleteRow1(rowIndex) {   // for positive row deletion
    this.j = this.j - 1;
    let negative = this.NotesForm.get('negative') as FormArray;
    negative.removeAt(rowIndex)
  }

  onDeleteNutshellRow(rowIndex) {   // for day_in_nutshell row deletion
    this.k = this.k - 1;
    let day_in_nutshell = this.NotesForm.get('day_in_nutshell') as FormArray;
    day_in_nutshell.removeAt(rowIndex)
  }

  onDeleteCuesFromNiftyDailyRow(rowIndex) {   // for cues_from_nifty_daily row deletion
    this.l = this.l - 1;
    let cues_from_nifty_daily = this.NotesForm.get('cues_from_nifty_daily') as FormArray;
    cues_from_nifty_daily.removeAt(rowIndex)
  }

  onDeleteStocksToWatchRow(rowIndex) {   // for stocks_to_watch row deletion
    this.m = this.m - 1;
    let stocks_to_watch = this.NotesForm.get('stocks_to_watch') as FormArray;
    stocks_to_watch.removeAt(rowIndex)
  }

  onDeleteLastWeekNutshellRow(rowIndex) {   // for last_week_nutshell row deletion
    this.n = this.n - 1;
    let last_week_nutshell = this.NotesForm.get('last_week_nutshell') as FormArray;
    last_week_nutshell.removeAt(rowIndex)
  }

  onDeleteCuesFromNiftyWeeklyRow(rowIndex) {   // for cues_from_nifty_weekly row deletion
    this.o = this.o - 1;
    let cues_from_nifty_weekly = this.NotesForm.get('cues_from_nifty_weekly') as FormArray;
    cues_from_nifty_weekly.removeAt(rowIndex)
  }

  onDeleteStocksOnWeeklyRadarRow(rowIndex) {   // for stocks_on_weekly_radar row deletion
    this.p = this.p - 1;
    let stocks_on_weekly_radar = this.NotesForm.get('stocks_on_weekly_radar') as FormArray;
    stocks_on_weekly_radar.removeAt(rowIndex)
  }

  onDeleteRankingRow(rowIndex) {   // for ranking row deletion
    this.p = this.p - 1;
    let stocks_on_weekly_radar = this.NotesForm.get('stocks_on_weekly_radar') as FormArray;
    stocks_on_weekly_radar.removeAt(rowIndex)
  }
  
  onSubmit() {

    let positive = this.NotesForm.get('positive').value
    this.formData = this.NotesForm.value;
    let cdate= moment(this.NotesForm.value.cdate).format('YYYY-MM-DD');
    let created_on= moment(this.NotesForm.value.created_on).format('YYYY-MM-DD');
    // console.log(this.formData);
    // let cdate = this.commonService.formatDate(this.NotesForm.value.cdate);

    let jsonData = {
      "cdate": cdate,
      "created_on":created_on,
      "cues_from_nifty_daily": this.NotesForm.value.cues_from_nifty_daily,
      "cues_from_nifty_weekly": this.NotesForm.value.cues_from_nifty_weekly,
      "day_in_nutshell": this.NotesForm.value.day_in_nutshell,
      "id": this.NotesForm.value.id,
      "last_week_nutshell": this.NotesForm.value.last_week_nutshell,
      "negative": this.NotesForm.value.negative,
      "positive": this.NotesForm.value.positive,
      "ranking": this.NotesForm.value.ranking,
      "stocks_on_weekly_radar": this.NotesForm.value.stocks_on_weekly_radar,
      "stocks_to_watch": this.NotesForm.value.stocks_to_watch
    }
    console.log('JsonData------>>', jsonData);
    // console.log('cdate ---------->>', this.NotesForm.value.cdate,'----',this.NotesForm.value.cdate=='');
    if (this.NotesForm.value.cdate != '' && this.NotesForm.value.created_on != '') {
      this.rmnotesService.addRMNotesData(this.formData).subscribe((posts:any) => {
        // console.log('posts---------->>', posts);
        if (posts.statusCode == 200) {
          this.snackBar.open('Notes updated Successfully', '', {
            duration: 3000
          });
          // this.ngOnInit();
          this.Router.navigate(['/editornotes']);
          this.ngOnInit();
          // this.clearAllFields();
        } else {
          this.snackBar.open('something went wrong', '', {
            duration: 3000
          });
        }

      })
    } else {
      this.snackBar.open('Please Choose Date before submitting the Form.', '', {
        duration: 3000
      });
    }
  }

  onChangeSymbol(symbol) {
    // console.log('change event---->>',symbol);  
    this.onChangeResult = this.symbolArr.filter(s => s.symbol == symbol);
    // console.log('result---->>',this.onChangeResult);
    let data = { "symbol": symbol, "date": this.onChangeResult[0].timestamp, "expiry_dt": this.onChangeResult[0].expiry_dt }
    this.commonService.viewSymbolDetails(data).subscribe((posts:any) => {
      this.onChangeResult1 = [posts.result];
      // console.log('onChangeResult1---->>',this.onChangeResult1); 
    })
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



  ngOnInit() {
    this.edit_id = this.activatedRoute.snapshot.params['id'];
    // console.log('moment date-->>',moment.locale());
    // console.log('edit_id---->',this.edit_id,'-----',this.edit_id==undefined);
    let data;
    // console.log('selected Tab--->>', this.selected.value);
    if (this.edit_id != undefined) {
      let day_in_nutshell = this.NotesForm.get('day_in_nutshell') as FormArray;
      let positive = this.NotesForm.get('positive') as FormArray;
      let negative = this.NotesForm.get('negative') as FormArray;
      let cues_from_nifty_daily = this.NotesForm.get('cues_from_nifty_daily') as FormArray;
      let stocks_to_watch = this.NotesForm.get('stocks_to_watch') as FormArray;
      let last_week_nutshell = this.NotesForm.get('last_week_nutshell') as FormArray;
      let cues_from_nifty_weekly = this.NotesForm.get('cues_from_nifty_weekly') as FormArray;
      let stocks_on_weekly_radar = this.NotesForm.get('stocks_on_weekly_radar') as FormArray;
      let ranking = this.NotesForm.get('ranking') as FormArray;
      day_in_nutshell.removeAt(0);     //clear 0th position data
      positive.removeAt(0);     //clear 0th position data
      negative.removeAt(0);     // clear 0th position data 
      cues_from_nifty_daily.removeAt(0);     //clear 0th position data
      stocks_to_watch.removeAt(0);     //clear 0th position data
      last_week_nutshell.removeAt(0);     //clear 0th position data
      cues_from_nifty_weekly.removeAt(0);     //clear 0th position data
      stocks_on_weekly_radar.removeAt(0);     //clear 0th position data
      ranking.removeAt(0);     //clear 0th position data

      data = { "id": this.edit_id }
      this.rmnotesService.viewFinsparcEditorsNotes(data).subscribe((posts:any) => {
        this.posts = posts.result;
        // console.log('posts----patch-->>', posts.result);
        // console.log('this.posts[0].positive----->>',this.posts[0].positive);
        // console.log('this.posts[0].stocks_to_watch------>>',this.posts[0].stocks_to_watch);
        this.NotesForm.patchValue({
          id: this.posts[0].id,
          // day_in_nutshell: this.posts[0].day_in_nutshell,
          day_in_nutshell: this.posts[0].day_in_nutshell.forEach(element => {
            day_in_nutshell.push(this._fb.group({
              id: element.id,
              data: element.data,
            }));
          }),
          cdate: this.posts[0].cdate,
          // cues_from_nifty_daily: this.posts[0].cues_from_nifty_daily,
          cues_from_nifty_daily: this.posts[0].cues_from_nifty_daily.forEach(element => {
            cues_from_nifty_daily.push(this._fb.group({
              id: element.id,
              data: element.data,
            }));
          }),
          // stocks_to_watch: this.posts[0].stocks_to_watch,
          stocks_to_watch: this.posts[0].stocks_to_watch.forEach(element => {
            stocks_to_watch.push(this._fb.group({
              id: element.id,
              data: element.data,
            }));
          }),
          // last_week_nutshell: this.posts[0].last_week_nutshell,
          last_week_nutshell: this.posts[0].last_week_nutshell.forEach(element => {
            last_week_nutshell.push(this._fb.group({
              id: element.id,
              data: element.data,
            }));
          }),
          // cues_from_nifty_weekly: this.posts[0].cues_from_nifty_weekly,
          cues_from_nifty_weekly: this.posts[0].cues_from_nifty_weekly.forEach(element => {
            cues_from_nifty_weekly.push(this._fb.group({
              id: element.id,
              data: element.data,
            }));
          }),
          // stocks_on_weekly_radar: this.posts[0].stocks_on_weekly_radar,
          stocks_on_weekly_radar: this.posts[0].stocks_on_weekly_radar.forEach(element => {
            stocks_on_weekly_radar.push(this._fb.group({
              id: element.id,
              data: element.data,
            }));
          }),
          // ranking: this.posts[0].ranking,
          ranking: this.posts[0].ranking.forEach(element => {
            ranking.push(this._fb.group({
              id: element.id,
              data: element.data,
            }));
          }),

          positive: this.posts[0].positive.forEach(element => {
            console.log('ele---->>', element);
            positive.push(this._fb.group({
              id: element.id,
              data: element.data,
            }));
          }),
          negative: this.posts[0].negative.forEach(element => {
            console.log('ele---->>', element);
            negative.push(this._fb.group({
              id: element.id,
              data: element.data,
            }));
          }),
        })

      });
    }

    data = { "id": 0 }
    console.log('data------>>', data);
    this.rmnotesService.viewFinsparcEditorsNotes(data).subscribe((posts:any) => {
      this.posts = posts.result;
      console.log('posts-----init----->>', posts.result)
      this.dataSource = new MatTableDataSource(this.posts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });

    this.commonService.viewSymbolList(null).subscribe((posts:any) => {
      this.symbolArr = posts.result;
      console.log('symbolArr-----init----->>', this.symbolArr);
      // this.dataSource = new MatTableDataSource(this.symbolArr);
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;

    });

  }



  onTabChange(event) {
    let selectedTab = event;
    console.log('event----->>', selectedTab);
  }


  openDialog(id, description, lengths): void {
    console.log("note id ---=-=-=>", id)
    const dialogRef = this.dialog.open(NoteDialog, {
      // width: '250px',
      // data: mydata
      data: {
        "id": id,
        "description": description,
        "lenght": lengths
      }
    });
    // debugger
    dialogRef.afterClosed().subscribe(result => {
      console.log('The note dialog was closed');
    });
  }

  onDelete(id) {

    console.log("id-=-=-=-=-=->", id)
    var data = {
      "id": id
    }

    // this.datacalls.DeleteFinsparcEditorsNotes(data).subscribe(posts => {
    //   // this.posts = posts.result;
    //   this.snackBar.open('Notes Deleted Successfully', '', {
    //     duration: 2000
    //   });
    //   this.ngOnInit();
    // });

  }

}



/***************************Start Note Dialog********************************************/

@Component({
  selector: 'note-dialog',
  templateUrl: 'note-dialog.html',
  // styleUrls: [''],
  providers: [DatacallsService]
})
export class NoteDialog implements OnInit {
  posts;
  // data1;
  description;
  abc;
  daya;


  constructor(
    public dialogRef: MatDialogRef<NoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private _DatacallsService: DatacallsService) {
    console.log('dialog data----->', data)
  }

  ngOnInit() {
    // let data1 = {
    //   des: "this.data.description"
    // }


    // const myObjStr = JSON.stringify(data1);
    // const myObjStr = parseInt(this.data.description);

    this.daya = this.data.description;

    //  .slice(0,this.abc.lenght);

    console.log('daya', this.daya);

    // console.log('dia', this.abc.description.slice(0,this.abc.lenght))

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogData {
  description: string;
}