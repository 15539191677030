import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MatDialog, MatDialogRef, MatOption, MatSelect, MatSnackBar, MatSort, MatTableDataSource, MAT_DIALOG_DATA, Sort } from '@angular/material';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ReportsService } from 'src/app/services/apis/reports.service';
import { AppService } from 'src/app/services/app.service';
import { FiltersService } from 'src/app/services/filters.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PaginationService } from 'src/app/services/pagination.service';
import { DatacallsService } from 'src/app/datacalls.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
// import { DialogData } from 'src/app/editor-notes/editor-notes.component';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-mis',
  templateUrl: './mis.component.html',
  styleUrls: ['./mis.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),],
    providers:[DatacallsService]
})
export class MisComponent implements OnInit {

  filters;
  currentPage = 1;
  list=[{"column":"Near","value":"near"},{"column":"Next","value":"next"},{"column":"Far","value":"far"}];
  /* displayedColumns: string[] = ['id', 'timestamp', 'expiry_dt', 'nifty_close', 'nifty_base_price', 'banknifty_close', 'banknifty_base_price', 'market_mood_index', 'mood', 'nifty_call_ratio', 'nifty_put_ratio', 'banknifty_call_ratio', 'banknifty_put_ratio', 'market_wide_call_ratio', 'market_wide_put_ratio', 'nifty_score', 'banknifty_score', 'stock_score', 'no_of_shares_greater55', 'no_of_shares_lessthen45', 'score_between_45_to_55', 'contract_traded_in_future', 'contract_traded_in_option', 'tot_call_value', 'tot_put_value', 'market_wide_per_lot_future', 'market_wide_per_lot_option', 'abv_5dma', 'below_5dma', 'created_on']; */
  
  columnList=[{"column":"Symbol","value":"a.symbol","title":"Symbol","key":"symbol"},{"column":"Expiry Dt","value":"(a.expiry_dt::date)::text as expiry_dt","title":"ExpiryDate","key":"expiry_dt"},{"column":"High","value":"a.high","title":"High","key":"high"},{"column":"Low","value":"a.low","title":"Low","key":"low"},{"column":"Settle Price","value":"a.settle_pr","title":"Settle Price","key":"settle_pr"},{"column":"OI","value":"a.open_int","title":"Open Int","key":"open_int"},{"column":"Chg_in_oi","value":"a.chg_in_oi","title":"Chg_in_oi","key":"chg_in_oi"},{"column":"Volume","value":"a.volume","title":"Volume","key":"volume"},{"column":"Lot Size","value":"a.lot_size","title":"Lot Size","key":"lot_size"},{"column":"WAP","value":"a.wap","title":"WAP","key":"wap"},{"column":"Total Score","value":"round(d.score::numeric,0) as total_score","title":"Total Score","key":"total_score"},{"column":"Color","value":"e.color","title":"Color","key":"color"},{"column":"Rank","value":"f.rank","title":"Rank","key":"rank"},{"column":"Momentum Ratio","value":"round(g.momentum_ratio::numeric,2) as momentum_ratio","title":"MR","key":"momentum_ratio"},{"column":"Call Ratio","value":"round(b.call_ratio::numeric,2) as call_ratio","title":"Call Ratio","key":"call_ratio"},{"column":"Put Ratio","value":"round(b.put_ratio::numeric,2)as put_ratio","title":"Put Ratio","key":"put_ratio"},{"column":"Call Value","value":"round(b.call_value::numeric,2) as call_value","title":"Call Value","key":"call_value"},{"column":"Put Value","value":"round(b.put_value::numeric,2) as put_value","title":"Put Value","key":"put_value"},{"column":"Timestamp","value":"(a.timestamp::date)::text as timestamp","title":"Timestamp","key":"timestamp"},{"column":"NIFTY Price","value":"s3.close as nifty_close","title":"NIFTY Close","key":"nifty_close"}];

  displayedColumns: string[] = [ 'symbol','expiry_dt', 'high', 'low', 'settle_pr','open_int','chg_in_oi','volume','lot_size','wap','total_score','color','rank','momentum_ratio','call_ratio','put_ratio','call_value','put_value','nifty_close','timestamp'];

  pageNumbers = [];
  columnsToDisplay = this.columnList;
  
  @ViewChild('select') select: MatSelect;
  allSelected=true;

  misReportData = [];
  dataSource;
  symbols;
  currentDt;
  @ViewChild(MatSort) sort:MatSort;
  constructor(public snackBar: MatSnackBar, public reportsService: ReportsService, private filtersService: FiltersService, private route: ActivatedRoute, private appService: AppService, private paginationService: PaginationService,private DatacallsService:DatacallsService,public dialog: MatDialog,private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    } 

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    console.log('this.allSelected---->>', this.allSelected);
  }

  ngOnInit() {
    this.filters = this.filtersService.getMisReportFilters();

    this.reportsService.getCurrentData().subscribe((response: any) => {
      this.filters = {
        "startDate": response.current_dt,
        "endDate": response.current_dt,
        "symbols": [],
        "columns": [],
        "expiryState": "near",
        "limit": 250,
        "offset": 0
      }
      this.getCurrentPage();

      // console.log('this.filters------------------->>',this.filters);
    })

    this.filtersService.symbols.subscribe((symbols: string[]) => {
      this.symbols = symbols;
      // console.log("Let symbols ", symbols);
    });
  }

    ngOnDestroy() {
      this.filtersService.setMisReportFilters(this.filters);
    }

  getCurrentPage() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.currentPage = +params.get('page');
      // Set offset
      this.filters.offset = (this.currentPage - 1) * this.filters.limit;
      // Set From Date to 1 month Back
      /* if (this.filters.offset == 0) {
        this.filters.startDate.setMonth(this.filters.endDate.getMonth() - 1);
      } */

      // Make API Call to get the data from server with filters
      this.getMISReportWithFilters();
      console.log(this.filtersService.symbols);
    });
  }

  getFilterParams() {
    let dates = this.filtersService.transformFilterDates(this.filters);
    return {
      ...this.filters,
      ...dates
    };
  }

  setDataSource() {
    // console.log("Function called to show the filter Data-->>",this.misReportData);
    this.dataSource = new MatTableDataSource(this.misReportData);
    
    this.dataSource.sort = this.sort;
    // console.log('this.sort-------->>>', this.sort);
    const sortState: Sort = {active: 'symbol', direction: 'desc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  exportMisReport() {
    // console.log("Export MIS Report------------");
    // Change Date format
    let filterParams = this.getFilterParams();
    this.DatacallsService.exportMisReport(filterParams).subscribe(post=>{
      // console.log('post-->>',post)
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(post);
      link.download ="MIS Report_"+new Date().toLocaleDateString()+".xlsx";
      link.click();
      this.snackBar.open('File is downloaded .....', '', {
        duration: 3000
      });
    })
    // this.reportsService.exportMisReport(filterParams).subscribe((response: any) => {
    //   console.log("Response ----form server -------", response);
    //   this.appService.dowloadFile(response, 'MIS-Report-');
    //   // Show the download message toast
    //   this.snackBar.open('Your file will be downloaded soon!', '', {
    //     duration: 2000
    //   });
    // }, (error) => {
    //   console.log(error);
    // });
  }

  getMISReportWithFilters() {
    // let filterParams = this.getFilterParams();
    console.log('filterParams---get mis report data----->>',this.filters);
    // console.log('filterParams---get columns----->>',filterParams.columns);
    // console.log('this.filters--------------------->>',this.filters);
    let selectedColumnslist=this.filters.columns;
    let displayedColumnsNew=[];let columnsToDisplayNew=[];
    let defaultColumnsList=[];
    // console.log('selectedColumnslist.length--->>',selectedColumnslist.length);
    if(selectedColumnslist.length>0){
    selectedColumnslist.map((data,i)=>{
      // console.log('I------>>',data,i);
      this.columnList.filter(f=>{if(String(f.value)==String(data)){
        // delete f.value;delete f.column;
        columnsToDisplayNew.push({"column":f.column,"value":f.value,"title":f.title,"key":f.key});
        displayedColumnsNew.push(f.key);
      }});
    })
    this.columnsToDisplay=columnsToDisplayNew;
    this.displayedColumns=displayedColumnsNew;
    // console.log('columnsToDisplayNew------>>',columnsToDisplayNew);
    // console.log('defaultColumnsList------>>',defaultColumnsList);
    // console.log('displayedColumnsNew------>>',displayedColumnsNew);
  }
    
    this.reportsService.getMISReportWithFilters(this.filters).subscribe((response: any) => {
      // console.log("this.mis response -----------", response);
      if (response.statusCode == 200) {
        this.misReportData = response.result;
        // console.log("this.misReportData -----------", this.misReportData);
        this.pageNumbers = this.paginationService.getPageNumbers(this.filters.limit, response.totalData);
        this.setDataSource();
      }
    }, (error) => {
      console.log(error);
    });
  }

  filterChange(event = null) {
    this.filters.offset = 0;
    this.misReportData = [];
    if (event) { // of type multiple and modal is opened
      return;
    }
    console.log("Current Filter in dialog", this.filters);
    this.getMISReportWithFilters();
  }

  openColumnsDialog(): void {
    const dialogRef = this.dialog.open(selectColumnsDialog, {
      disableClose: true,
      restoreFocus: false,
      width: "70%",
      autoFocus: false,
      // height: "60%",
      data: {name: "test name", animal: "no-data"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

}
/************************Select Columns Dialog******************************************/
@Component({
  selector: 'app-mis',
  templateUrl: 'columns-dialog.html',
  styleUrls: ['./columns-dialog.scss'],
})
export class selectColumnsDialog {

  columnList=[{"column":"Symbol","value":"a.symbol","title":"Symbol","key":"symbol","no":1},{"column":"Expiry Dt","value":"(a.expiry_dt::date)::text as expiry_dt","title":"Expiry Dt","key":"expiry_dt","no":1},{"column":"High","value":"a.high","title":"High","key":"high","no":1},{"column":"Low","value":"a.low","title":"Low","key":"low","no":1},{"column":"Settle Price","value":"a.settle_pr","title":"Settle Price","key":"settle_pr","no":2},{"column":"Contracts","value":"a.contracts","title":"Contracts","key":"contracts","no":2},{"column":"OI","value":"a.open_int","title":"Open Int","key":"open_int","no":2},{"column":"Timestamp","value":"(a.timestamp::date)::text as timestamp","title":"Timestamp","key":"timestamp","no":2},{"column":"WAP","value":"a.wap","title":"WAP","key":"wap","no":3},{"column":"Total Score","value":"round(d.score::numeric,0) as total_score","title":"Total Score","key":"total_score","no":3},{"column":"Color","value":"e.color","title":"Color","key":"color","no":3},{"column":"Rank","value":"f.rank","title":"Rank","key":"rank","no":3},{"column":"Call Value","value":"round(b.call_value::numeric,2) as call_value","title":"Call Value","key":"call_value","no":4},{"column":"Put Value","value":"round(b.put_value::numeric,2) as put_value","title":"Put Value","key":"put_value","no":4},{"column":"Call Ratio","value":"round(b.call_ratio::numeric,2) as call_ratio","title":"put_ratio","key":"call_ratio"},{"column":"Put Ratio","value":"round(b.put_ratio::numeric,2)as put_ratio","title":"Put Ratio","key":"put_ratio"},{"column":"NIFTY Price","value":"s3.close as nifty_close","title":"NIFTY Close","key":"nifty_close"},{"column":"Lot Size","value":"a.lot_size","title":"Lot Size","key":"lot_size"},{"column":"Momentum Ratio","value":"round(g.momentum_ratio::numeric,2) as momentum_ratio","title":"MR","key":"momentum_ratio"}];
  filters;
  misReportData = [];
  columnLen=this.columnList.length;
  myForm: FormGroup;
  allComplete: boolean = false;
  columnFormArray;

  // allSelected=true;
  // @ViewChild('select') select: MatSelect;

  constructor(
    public dialogRef: MatDialogRef<selectColumnsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private filtersService: FiltersService,public snackBar: MatSnackBar,private fb: FormBuilder
  ) {}

  // toggleAllSelection() {
  //   if (this.allSelected) {
  //     this.select.options.forEach((item: MatOption) => item.select());
  //   } else {
  //     this.select.options.forEach((item: MatOption) => item.deselect());
  //   }
  // }
  // optionClick() {
  //   let newStatus = true;
  //   this.select.options.forEach((item: MatOption) => {
  //     if (!item.selected) {
  //       newStatus = false;
  //     }
  //   });
  //   this.allSelected = newStatus;
  //   console.log('this.allSelected---->>', this.allSelected);
  // }


  ngOnInit() {
    this.filters = this.filtersService.getMisReportFilters();
    // this.columnFormArray.value=[];
    this.myForm = this.fb.group({
      columns: this.fb.array([])
    });
  }

  onChange(value: string, isChecked: boolean) {
    this.columnFormArray = <FormArray>this.myForm.controls.columns;
    
    if (isChecked) {
      this.columnFormArray.push(new FormControl(value));
      console.log('this.columnFormArray--->>',this.columnFormArray);
      console.log('this.columnFormArray.value--->>',this.columnFormArray.value);
    } else {
      let index = this.columnFormArray.controls.findIndex(x => x.value == value);
      this.columnFormArray.removeAt(index);
      console.log('this.columnFormArray--removed->>',this.columnFormArray);
      console.log('this.columnFormArray--removed --value->>',this.columnFormArray.value);
    }
  }

  onAllSelect(event){
    this.columnFormArray = <FormArray>this.myForm.controls.columns; 

    console.log('checkbox event ------>>',event);
    if(event.checked==true){
      // console.log(' this.columnFormArray------>>', this.columnFormArray.value);
      // console.log('this.columnList------>>',this.columnList);
    this.columnList.map(i=>{
      console.log('i----------->>',i);
      this.columnFormArray.push(new FormControl(i.value));
    })
  }else{
    this.columnFormArray.value.map(val=>{
      let index = this.columnFormArray.controls.findIndex(x => x.value == val);
      console.log(' clear---i---->>',index);
      this.columnFormArray.isChecked=false;
      this.columnFormArray.removeAt(index);
      this.ngOnInit();
    })
  }
console.log(' this.columnFormArray---updated all ----->>', this.columnFormArray.value);
  }
  getFilterParams() {
    let dates = this.filtersService.transformFilterDates(this.filters);
    return {
      ...this.filters,
      ...dates
    };
  }

  getMISReportWithFilters() {
    let filterParams = this.getFilterParams();
    console.log('filterParams---get mis report data----->>',filterParams);
    console.log('filterParams---get columns----->>',filterParams.columns);
  //   let selectedColumnslist=filterParams.columns;
  //   let displayedColumnsNew=[];let columnsToDisplayNew=[];
  //   let defaultColumnsList=[];
  //   console.log('selectedColumnslist.length--->>',selectedColumnslist.length);
  //   if(selectedColumnslist.length>0){
  //   selectedColumnslist.map((data,i)=>{
  //     // console.log('I------>>',data,i);
  //     this.columnList.filter(f=>{if(String(f.value)==String(data)){
  //       // delete f.value;delete f.column;
  //       columnsToDisplayNew.push({"column":f.column,"value":f.value,"title":f.title,"key":f.key});
  //       displayedColumnsNew.push(f.key);
  //     }});
  //   })
  //   this.columnsToDisplay=columnsToDisplayNew;
  //   this.displayedColumns=displayedColumnsNew;
  //   console.log('columnsToDisplayNew------>>',columnsToDisplayNew);
  //   console.log('defaultColumnsList------>>',defaultColumnsList);
  //   console.log('displayedColumnsNew------>>',displayedColumnsNew);
  // }
    
  //   this.reportsService.getMISReportWithFilters(filterParams).subscribe((response: any) => {
  //     console.log("Response -----------", response);
  //     if (response.statusCode == 200) {
  //       this.misReportData = response.result;
  //       this.pageNumbers = this.paginationService.getPageNumbers(this.filters.limit, response.totalData);
  //       this.setDataSource();
  //     }
    // }, (error) => {
    //   console.log(error);
    // });
  }

  filterChange(event = null) {
    this.filters.offset = 0;
    this.misReportData = [];
    if (event) { // of type multiple and modal is opened
      return;
    }
    console.log("Current Filter ", this.filters);
    // this.getMISReportWithFilters();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

