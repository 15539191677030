import { Component, OnInit } from '@angular/core';
import { DatacallsService } from 'src/app/datacalls.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { IndustriesService } from 'src/app/services/apis/industries.service';

@Component({
  selector: 'app-add-industry',
  templateUrl: './add-industry.component.html',
  styleUrls: ['./add-industry.component.scss'],
  providers: [DatacallsService]
})
export class AddIndustryComponent implements OnInit {
  id;
  industries: Array<{
    name: string
  }> = [{
    name: ""
  }];
  industryList;symbolList;
  //***********************
  name = 'Angular';
  productForm: FormGroup;
  //***********************
  industryForm;formData;
  selectedIndustry;selectedSymbols;
  constructor(private DatacallsService: DatacallsService, private ActivatedRoute: ActivatedRoute, public snackBar: MatSnackBar, private router: Router,private industriesService: IndustriesService,private _fb: FormBuilder) {
    this.industryForm = this._fb.group({
      industry_id: [0],
      symbol_id: [0],
      industry_name: [''],
      symbol_name: ['']
    })
  }

  ngOnInit() {
    // getUnallocateSymbolAndIndustryList
    this.industriesService.getUnallocateSymbolAndIndustryList().subscribe((data: any) => {
      let dataSet=data;
      this.industryList=data.industries;
      this.symbolList=data.symbols;
      console.log('dataset--->>',dataSet);
      console.log('in dustry list--->>',this.industryList);
      console.log('in symbol list--->>',this.symbolList);
      
      })
  }

  onSubmitIndustry() {
    let industry_id=this.selectedIndustry.value.id;
    let symbol_id=this.selectedSymbols.value.id;
    let data={"data":[{"industry_id":industry_id,"symbol_id":symbol_id}]}
    console.log('selected Industry--->>',industry_id);
    console.log('selected symbol--->>',symbol_id);
    this.industriesService.allocateSymbol(data).subscribe((data: any) => {
      console.log('data--->>',data);
      if(data.statusCode==200){
        this.snackBar.open('Symbol Allocation Done successfully!', '', {
          duration: 2000
        });
        this.ngOnInit();
      }else{
        this.snackBar.open('Something Went Wrong', '', {
          duration: 2000
        });
      }
      })

  }
  seletedIndustry(i){
    this.selectedIndustry=i;
    console.log('form data---i--->>',i);
  }
  seletedSymbol(s){
    this.selectedSymbols=s;
    console.log('form data---s--->>',s);
  }
  // Add the industries in an array
  addMore() {
    this.industries.push({
      name: ""
    });
  }

  // Delete Industry 
  removeIndustry(index) {
    this.industries.splice(index, 1);
  }

  /*  onSubmit() {
     let postData = this.productForm.value.industries;
     console.log('values--------->>', postData);
     let data = [];
 
     postData.forEach((element) => {
       let spaceRemoved = element.qty.replace(/\s+/g, '');
       let myString = spaceRemoved.concat('');
       console.log("string -------->>", myString);
       console.log(element);
       data.push(myString);
       // data.push((element.qty).replace(/\s+/g, '')); //repace white space
     });
     console.log("data--------------->>", data);
 
     let dataSubmit = {
       "name": data
     }
     console.log('this dataSubmit -=-=-=>', dataSubmit.name);
     this.DatacallsService.insert_industry_stock(dataSubmit).subscribe(posts => {
       this.post = posts;
       console.log('posts---> ', this.post);
       if (this.post.statusCode == 200) {
         this.snackBar.open('Data Insertion Done Successfully', '', {
           duration: 2000
         });
 
         this.router.navigate(['/housekeeping/industry/']);
       } else {
         this.snackBar.open('Something Went Wrong', '', {
           duration: 2000
         });
       }
     })
   } */

  validateIndustries() {
    if (this.industries.length > 0) {
      let uniqueNames = [];
      this.industries = this.industries.filter((industry) => {
        industry.name = industry.name.trim();
        let index;
        if (industry.name) {
          index = uniqueNames.indexOf(industry.name);
          if (index == -1) {
            uniqueNames.push(industry.name);
          }
        }
        return index == -1;
      });
      return this.industries.length > 0;
    } else if (!this.industries[0].name) {
      // TODO Show empty value error
      return false;
    }
  }


  createIndustries() {
    // Filter industries and remove empty values
    let isValid = this.validateIndustries();
    console.log("Valid *************", isValid);
    console.log("************* New Industries *************", this.industries);
    let arr=this.industries.map(item=>{return item.name;});
    let data={"name":arr};
    console.log('data--->>',data);
    if (isValid) { // At least 1 non empty industry
      this.industriesService.addIndustry(data).subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.snackBar.open('Industries created successfully!', '', {
            duration: 2000
          });

          this.router.navigate(['/housekeeping/industry/']);
        } else {
          this.snackBar.open('Something Went Wrong', '', {
            duration: 2000
          });
        }
      }, (error) => {
        console.log("API Error ", error);
        this.snackBar.open('Something Went Wrong', '', {
          duration: 2000
        });
      });
    }
  }

}
