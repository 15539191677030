import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }

  getFormData(rawObject) {
    let formObject = new FormData();
    // Loop through object
    for (let key in rawObject) {
      if (key) { // If key is set
        // Append the value with that key in form object
        formObject.append(key, rawObject[key]);
      }
    }
    return formObject;
  }

  getParamsObject(rawObject) {
    let httpParams = new HttpParams();
    // Loop through object
    for (let key in rawObject) {
      if (key) { // If key is set
        // Append the value with that key in form object
        httpParams = httpParams.set(key, rawObject[key]);
      }
    }
    return httpParams;
  }


  dowloadFile(data, fileName) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = fileName + (new Date().toLocaleDateString()) + ".xlsx";
    link.click();
  }
}
