import { Component, OnInit } from '@angular/core';
import { DatacallsService } from 'src/app/datacalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { IndustriesService } from 'src/app/services/apis/industries.service';
import { CommonService } from 'src/app/services/apis/common.service';


@Component({
  selector: 'app-add-nifty50-stock',
  templateUrl: './add-nifty50-stock.component.html',
  styleUrls: ['./add-nifty50-stock.component.scss'],
  providers: [DatacallsService]
})
export class AddNifty50StockComponent implements OnInit {
  id;
  lotForm;
  post;
  posts;
  parent_id;
  symbolList;
  selectedSymbolList;
  constructor(private DatacallsService: DatacallsService, private ActivatedRoute: ActivatedRoute, private FormBuilder: FormBuilder, public snackBar: MatSnackBar, private router: Router,private industriesService: IndustriesService,private commonService:CommonService) {
    this.lotForm = this.FormBuilder.group({
      id: [0],
      name: [''],
    })

  }

  ngOnInit() {
    this.id = this.ActivatedRoute.snapshot.params['id'];
    console.log("patch value id -->", this.id);

    if (this.id != 'undefined') {
      // this.DatacallsService.viewNifty50(this.id).subscribe(posts => {
        this.industriesService.viewNifty50New(null).subscribe((response: any) => {
        this.posts = response.result
        console.log('posts---=>', this.posts);

        this.lotForm.patchValue({
          id: this.posts[0].id,
          name: this.posts[0].name,
        })

      });
    }
    this.commonService.getAllSymbolList().subscribe((posts:any) => {
      this.symbolList = posts.result;
      console.log('symbolList------->>', this.symbolList);
    })

  }

  seletedSymbol(s) {
    this.selectedSymbolList = s.value;
    console.log('form data---s--->>', this.selectedSymbolList);
  }

  onSubmit() {
    let symbol = this.selectedSymbolList.symbol;
    var data = {
      "data": [{ "symbol": symbol }]
    }
    console.log('this data -=-=-=>', data);
    this.industriesService.addNifty50Stock(data).subscribe((posts:any) => {
      this.post = posts;
      console.log('posts---> ', this.post);
      if (this.post.statusCode == 200) {
        this.snackBar.open('Data Insertion Done Successfully', '', {
          duration: 2000
        });
      }
      else if (this.post.statusCode == 409) {
        this.snackBar.open(symbol + ' Alreday  Exist !', '', {
          duration: 2000
        });
      }
    })
  }

}
