import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/apis/user.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = '../assets/img/users/user.jpg';
  constructor(public router:Router,private userService:UserService) { }

  ngOnInit() {
  }

  onClick(){
    let data=JSON.parse(sessionStorage.getItem('currentuser'));
    let json=({"token":data['refresh-token']});
    this.userService.logout(json).subscribe((posts:any) => {
      console.log('logout hit',posts);
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate([' ']);
    })
  }

}
