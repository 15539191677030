import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs/Observable";

@Injectable({
  providedIn: 'root'
})
export class AppHttpInterceptorService implements HttpInterceptor {

  token: string;
  usertype: string;
  constructor() {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let data=JSON.parse(sessionStorage.getItem('currentuser'));
      this.token=data['access-token'];
      this.usertype=data['usertype'];
      if (this.token) {
          // const tokenizedReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.token) });
          const tokenizedReq = req.clone({ headers: req.headers.set('authorization',this.token)
                                                                .set('usertype',this.usertype) });
          return next.handle(tokenizedReq);
        }else{
        return next.handle(req);
        }

  }
}
