import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppApiService } from './apis/app-api.service';
import { ReportsService } from './apis/reports.service';


@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  symbols: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  fromDate;
  toDate;
  strikePrices: [];

  misReportFilters = {
    "startDate": "",
    "endDate": "",
    "symbols": [],
    "columns": [],
    "expiryState": "near",
    "limit": 250,
    "offset": 0
  }

  dailyReportFilters = {
    "date": "",
    "symbols":"NIFTY",
    "expiry_state": "near",
    "type":"monthly"
  }

  futureNewReportFilters = {
    "date": "",
    "expiry_state": "near",
    "oi_val_g":"",
    "oi_val_l":"",
    "oi_qty_g":"",
    "oi_qty_l":"",
    "chg_oi_qty_g":"",
    "chg_oi_qty_l":"",
    "chg_oi_val_g":"",
    "chg_oi_val_l":"",
    "call_velocity_g":"",
    "call_velocity_l":"",
    "put_velocity_g":"",
    "put_velocity_l":"",
    "chg_oi_2days_per_g":"",
    "chg_oi_2days_per_l":"",
    "chg_oi_5days_per_g":"",
    "chg_oi_5days_per_l":"",
    "per_chg_oi_by_vol_g":"",
    "per_chg_oi_by_vol_l":""
  }

  optionReport3Filters = {
    "date": "",
    "symbols":"",
    "expiry_state": "near",
    "option_type":"CE",
    "strike_pr":"",
    "report": "call_put_value"
  }

  callPutReportFilters = {
    "startDate": "",
    "endDate": "",
    "symbols":"",
    "expiry_state": "near"
  }

  futureReportFilters = {
    "startDate": "",
    "endDate": "",
    "expiryState":"",
    // oifilters
    "oiFilter": "",
    "oiFilter_input": "",
    // color
    "color": "",
    // Price Filter
    "price": "",
    "price_input": "",
    // Price Avg Filter
    "price_avg": "",
    "price_avg_input": "",
    // TP/ Momentum Filter
    "trigger_price": "",
    // Score Filter
    "score":"",
    "score_input":"",
    "score_input1": "",
    // Rank
    "rank":"",
    "rank_input":"",
    "rank_input1":"",
    // Call Put Filter
    "call_put_velocity":"",
    "call_put_velocity_input":""
  }
  // futureReportFilters = {
  //   "startDate": "",
  //   "endDate": "",
  //   // oifilters
  //   "oi_qty_g": false, "oi_qty_g_input": "",
  //   "chg_oi_qty_per_g": false, "chg_oi_qty_per_g_input": "",
  //   "chg_oi_qty_g": false, "chg_oi_qty_g_input": "",
  //   "premium_g": false, "premium_g_input": "",
  //   "oi_qty_l": false, "oi_qty_l_input": "",
  //   "chg_oi_qty_per_l": false, "chg_oi_qty_per_l_input": "",
  //   "chg_oi_qty_l": false, "chg_oi_qty_l_input": "",
  //   "premium_l": false, "premium_l_input": "",

  //   "oi_value_g": false, "oi_value_g_input": "",
  //   "chg_oi_value_lakh_g": false, "chg_oi_value_lakh_g_input": "",
  //   "chg_oi_2days_per_g": false, "chg_oi_2days_per_g_input": "",
  //   "oi_5days_per_g": false, "oi_5days_per_g_input": "",
  //   "oi_for_days": false, "oi_for_days_input": "",
  //   "chg_oi_volume_per_g": false, "chg_oi_volume_per_g_input": "",
  //   // color 
  //   "red":false,"green":false,"blue":false,
  //   // price filters
  //   "chg_price_1day_g": false, "chg_price_1day_g_input": "",
  //   "chg_price_5day_per_g": false, "chg_price_5day_per_g_input": "",
  //   "ppl": false, "ppl_input": "",
  //   "ppl_5days_g": false, "ppl_5days_g_input": "",
  //   //price avg filter
  //   "stock_days_high": false, "stock_days_high_input": "",
  //   "stock_days_avg": false, "stock_days_avg_input": "",
  //   "stock_days_avg_volume": false, "stock_days_avg_volume_input": "",
  //   // MR Become +ve or -ve
  //   "price_greater_tp": false, "price_less_tp": false, "mr": false,
  //   // Socre
  //   "score_greater":false,"score_greater_input":"",
  //   "score_less":false,"score_less_input":"",
  //   //  Rank
  //   "rank_greater":false,"rank_greater_input":"",
  //   "rank_less":false,"rank_less_input":"",
  //   // Call put vellocity
  //   "call_vellocity_greater":false,"call_vellocity_greater_input":"",
  //   "put_vellocity_greater":false,"put_vellocity_greater_input":"",

  //   "limit": 250,
  //   "offset": 0
  // }

  bhavcopyFilters = {
    startDate: new Date(),
    endDate: new Date(),
    symbols: [],
    asset_type: "",
    instrument_type: "",
    strike_pr: [],
    offset: 0,
    limit: 250
  }

  fiiFilters = {
    // "symbols": [],
    "startDate": new Date(),
    "endDate": new Date(),
    "limit": 250,
    "offset": 0
  }

  vixFilters = {
    // "symbols": [],
    "startDate": new Date(),
    "endDate": new Date(),
    "limit": 250,
    "offset": 0
  }

  oiReportFilters = {
    "startDate": new Date(),
    "endDate": new Date(),
    "symbols": [],
    "option_type": "CE",
    // "expiryDate": "",
    "limit": 250,
    "offset": 0
  }

  plReportFilters = {
    "startDate": new Date(),
    // "symbols": [],
    "type": 1,
    // "expiryDate": "",

  }

  rankReportFilters = {
    "startDate": new Date(),
    "endDate": new Date(),
    // "symbols": [],
    // "option_type":[],
    // "expiryDate": "",
    "limit": 250,
    "offset": 0
  }

  weeklyFortnightReportFilters = {
    "startDate": new Date(),
    // "endDate": new Date(),
    // "symbols": [],
    "option_type": "",
    // "expiryDate": "",
    "limit": 250,
    "offset": 0
  }

  addingOIReportFilters = {
    "startDate": new Date(),
    "endDate": new Date(),
    "option_type": "CE",
    "symbols": [],
    "oi_qty": "",
    "oi_value": "",
    "oi_qty_per": "",
    "limit": 250,
    "offset": 0
  }

  optionReportFilters = {
    "startDate": new Date(),
    "option_type": "CE",
    "oi_2days": "",
    "oi_5days": "",
    "limit": 250,
    "offset": 0
  }

  optionReport2Filters = {
    "startDate": new Date(),
    "option_type": "CE",
    "change_oi_value": "",
    "limit": 250,
    "offset": 0
  }

  historicalDataReportFilters = {
    "startDate": new Date(),
    "endDate": new Date(),
    // "symbols": [],
    // "columns": [],
    // "expiryDate": "",
    // "limit": 250,
    // "offset": 0
  }

  optionsReportFilters = {
    "startDate": new Date(),
    "endDate": new Date(),
    "option_type": "",
    "limit": 250,
    "offset": 0
  }

  ivReportFilters = {

    "symbols": [],
    "startDate": new Date(),
    "endDate": new Date(),
    "limit": 100,
    "offset": 0

  }

  constructor(private appApiService: AppApiService, private ReportsService: ReportsService) {
    // Get Symbols From An API
    this.getSymbols();
  }


  getBhavcopyFilters() {
    return this.bhavcopyFilters;
  }

  setBhavcopyFilters(filters) {
    this.bhavcopyFilters = filters;
  }

  getFiiFilters() {
    return this.fiiFilters;
  }

  setFiiFilters(filters) {
    this.fiiFilters = filters;
  }

  getVixFilters() {
    return this.vixFilters;
  }

  setVixFilters(filters) {
    this.vixFilters = filters;
  }

  getOIReportFilters() {
    return this.oiReportFilters;
  }

  getPLReportFilters() {
    return this.plReportFilters;
  }

  getRankReportFilters() {
    return this.rankReportFilters;
  }
  getWeeklyFortnightReportFilters() {
    return this.weeklyFortnightReportFilters;
  }

  getAddingOIReportFilters() {
    return this.addingOIReportFilters;
  }

  getOptionReportFilters() {
    return this.optionReportFilters;
  }
  getOptionReport2Filters() {
    return this.optionReport2Filters;
  }


  setOIReportFilters(filters) {
    this.oiReportFilters = filters;
  }

  getHistoricalDataReportFilters() {
    return this.historicalDataReportFilters;
  }

  setHistoricalDataReportFilters(filters) {
    this.historicalDataReportFilters = filters;
  }

  getMisReportFilters() {
    return this.misReportFilters;
  }

  getDailyReportFilters() {
    return this.dailyReportFilters;
  }
  getFutureNewReportFilters() {
    return this.futureNewReportFilters;
  }
  getOptionReport3Filters() {
    return this.optionReport3Filters;
  }

  getCallPutReportFilters() {
    return this.callPutReportFilters;
  }

  getFutureReportFilters() {
    return this.futureReportFilters;
  }

  setMisReportFilters(filters) {
    this.misReportFilters = filters;
  }

  setDailyReportFilters(filters) {
    this.dailyReportFilters = filters;
  }

  setFutureReportFilters(filters) {
    this.futureReportFilters = filters;
  }

  getOptionsReportFilters() {
    return this.optionsReportFilters;
  }

  setOptionsReportFilters(filters) {
    this.optionsReportFilters = filters;
  }

  getIVReportFilters() {
    return this.ivReportFilters;
  }

  setIVReportFilters(filters) {
    this.ivReportFilters = filters;
  }

  getSymbols() {
    console.log("Getting Symbols -------------");
    this.appApiService.getSymbols().subscribe((response: any) => {
      if (response.statusCode == 200) {
        this.symbols.next(response.result)
      }
    });
  }

  getStrikePrices(filters) {
    let filterParams = {
      symbols: filters.symbols,
      option_type: filters.instrument_type,
      startDate: filters.from_date,
      endDate: filters.to_date
    }
    let strikePricesSubject = new Subject<[]>();
    console.log("Getting Symbols -------------")

    this.appApiService.getStrikePrices(filterParams).subscribe((response: any) => {
      if (response.statusCode == 200) {
        strikePricesSubject.next(response.result)
      }
    });
    return strikePricesSubject.asObservable();
  }

  transformDate(dateStr) {
    // Convert date string to date object
    let date = new Date(dateStr);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return date.getFullYear() + "-" + (month < 10 ? (month.toString()).padStart(2, "0") : month) + "-" + (day < 10 ? (day.toString()).padStart(2, "0") : day);
  }

  transformFilterDates(filters) {
    return {
      startDate: this.transformDate(filters.startDate),
      endDate: this.transformDate(filters.endDate),
      expiryDate: this.transformDate(filters.expiryDate) == 'NaN-NaN-NaN' ? '' : this.transformDate(filters.expiryDate),
    }
  }


}
