import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

import { AgmCoreModule } from '@agm/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true               
};
import { CalendarModule } from 'angular-calendar';
import { SharedModule } from './shared/shared.module';
import { PipesModule } from './theme/pipes/pipes.module';
import { routing } from './app.routing';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AppSettings } from './app.settings';

import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { BhavcopyComponent } from './pages/bhavcopy/bhavcopy.component';
import { LotsizeComponent } from './pages/lotsize/lotsize.component';
import { HoildaysComponent } from './pages/hoildays/hoildays.component';

import { OptionScoresComponent } from './option-scores/option-scores.component';
import { EditorNotesComponent,NoteDialog } from './editor-notes/editor-notes.component';
import { AddnotesComponent } from './addnotes/addnotes.component';
import { NewSharedModule } from '../common/shared.module';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
// httpModule use to featch the data from data calls 
import { HttpModule, JsonpModule } from '@angular/http';
import { AuthguardGuard } from './authguard.guard';
import { AddScoreComponent } from './pages/add-score/add-score.component';
import { AddLotsizeComponent } from './pages/add-lotsize/add-lotsize.component';
import { IvrReportComponent } from './pages/ivr-report/ivr-report.component';
import { AddSettlementComponent } from './pages/add-settlement/add-settlement.component';
import { DataCurrentBhavcopyComponent } from './pages/data-current-bhavcopy/data-current-bhavcopy.component';
import { DataMarketPulseComponent } from './pages/data-market-pulse/data-market-pulse.component';
import { AddLotChangeComponent } from './pages/add-lot-change/add-lot-change.component';
import { AddNifty50StockComponent } from './pages/add-nifty50-stock/add-nifty50-stock.component';
import { AddIndustryComponent } from './pages/add-industry/add-industry.component';
import { confirmationDialog, viewStockDialog } from './pages/housekeeping/industry/industry.component';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { IndustrySymbolComponent } from './pages/housekeeping/industry-symbol/industry-symbol.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { selectColumnsDialog } from './pages/reports/mis/mis.component';
import { AddCorporateActionComponent } from './pages/add-corporate-action/add-corporate-action.component';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { AppHttpInterceptorService } from './appHttpInterceptor';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { AlgorithmComponent } from './pages/algorithm/algorithm.component';
import { DataProcessingComponent } from './pages/data-processing/data-processing.component';
import { DownloadComponent } from './pages/download/download.component';
import { HousekeepingComponent } from './pages/housekeeping/housekeeping.component';
import { ReportsComponent } from './pages/reports/reports.component';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,     
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNcjxo_35qnEG17dQvvftWa68eZWepYE0'
    }), 
    PerfectScrollbarModule, 
    CalendarModule.forRoot(),
    SharedModule,
    PipesModule,
    routing,
    
    NewSharedModule,RichTextEditorAllModule,CheckBoxModule, DialogModule, NumericTextBoxModule, HttpModule, JsonpModule,GridAllModule
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    BlankComponent,
    SearchComponent,
    NotFoundComponent,
    ErrorComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    BhavcopyComponent,
    LotsizeComponent,
    HoildaysComponent,
    OptionScoresComponent,
    EditorNotesComponent,
    NoteDialog,                          //editor note dialog 
    confirmationDialog,                 // industry Dialog
    viewStockDialog,                     // view industry dialog
    selectColumnsDialog,                //view all columns 
    AddnotesComponent,
    AddScoreComponent,
    AddLotsizeComponent,
    IvrReportComponent,
    AddSettlementComponent,
    DataCurrentBhavcopyComponent,
    DataMarketPulseComponent,
    AddLotChangeComponent,
    IndustrySymbolComponent,
    AddNifty50StockComponent,
    AddIndustryComponent,
    AddCorporateActionComponent,
    UserDetailsComponent,
    UnauthorizedComponent,
    AlgorithmComponent,
    DataProcessingComponent,
    DownloadComponent,
    HousekeepingComponent,
    ReportsComponent,
  ],
  entryComponents:[
    VerticalMenuComponent,NoteDialog,confirmationDialog,viewStockDialog,selectColumnsDialog
  ],
  providers: [ AuthguardGuard,
    AppSettings,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }