import { Component, OnInit,ViewChild } from '@angular/core';
import { DatacallsService } from 'src/app/datacalls.service';
import { MatSnackBar, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'app-ivr-report',
  templateUrl: './ivr-report.component.html',
  styleUrls: ['./ivr-report.component.scss'],
  providers:[DatacallsService]
})
export class IvrReportComponent implements OnInit {

  displayedColumns: string[] = ['name', 'symbol', 'lot_size','lot_size1', 'date'];
  dataSource;
  posts;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  fileToupload;
  constructor(private DatacallsService:DatacallsService,public snackBar:MatSnackBar) { }

  
  ngOnInit() {
//viewFinsparcIVReport 

// this.DatacallsService.viewFinsparcIVReport ().subscribe(posts => {
//   this.posts = posts.result;

//   console.log('posts data  iv report  ----> ',this.posts)
//   this.dataSource = new MatTableDataSource(this.posts);
//   this.dataSource.paginator = this.paginator
//   this.dataSource.sort = this.sort
// });

  }

  onChange(fileInput: any) {
    this.fileToupload = fileInput.target.files['0']
  }

  onSubmit() {

    var formData = new FormData();

    formData.append('file1', this.fileToupload);
    // this.DatacallsService.uploadIVReport(formData).subscribe(posts => {

    //   if (posts['Status'] == 200) {
    //     this.snackBar.open('Excel Uploded Successfully', '', {
    //       duration: 2000
    //     });
    //     this.ngOnInit();
    //   }
    //   else if(posts.result[0].status==203) 
    //   {
    //       this.snackBar.open('Column Name Incorrect', '', {
    //       duration: 2000
    //     });
    //   }
    //   else if(posts.result[0].status==404) 
    //   {
    //       this.snackBar.open('Please Choose File First', '', {
    //       duration: 2000
    //     });
    //   }
    //   else 
    //   {
        
    //       this.snackBar.open('You Can not upload same Data Again', '', {
    //       duration: 3000
    //     });
    //   }
    // });


  }

}
