import { Component, OnInit } from '@angular/core';
import { DatacallsService } from 'src/app/datacalls.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { IndustriesService } from 'src/app/services/apis/industries.service';
import * as moment from '../../utils/moment';
import { FileUploadService } from 'src/app/services/apis/file-upload.service';

@Component({
  selector: 'app-add-corporate-action',
  templateUrl: './add-corporate-action.component.html',
  styleUrls: ['./add-corporate-action.component.scss'],
  providers:[DatacallsService]
})
export class AddCorporateActionComponent implements OnInit {

  id;
  corporates: Array<{
    symbol: string,
    ex_date:string,
    adjustment_factor:Number,
    ca_type:Number
  }> = [{
    symbol: "",
    ex_date:"",
    adjustment_factor:null,
    ca_type:null
  }];
  industryList;symbolList;
  fileToupload2;
  //***********************
  name = 'Angular';
  productForm: FormGroup;
  //***********************
  corporateForm;formData;
  selectedIndustry;selectedSymbols;
  list = [{ "column": "Bonus", "value": "1" }, { "column": "Split", "value": "2" }, { "column": "Dividend", "value": "3" }, { "column": "Rights", "value": "4" }, { "column": "Demerger", "value": "5" }];
  constructor(private DatacallsService: DatacallsService, private ActivatedRoute: ActivatedRoute, public snackBar: MatSnackBar, private router: Router,private industriesService: IndustriesService,private _fb: FormBuilder,private fileUploadService: FileUploadService,private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    this.corporateForm = this._fb.group({ 
      industry_id: [0],
      symbol_id: [0],
      industry_name: [''],
      symbol_name: ['']
    })
  }

  ngOnInit() {
    // getUnallocateSymbolAndIndustryList
    // this.industriesService.getUnallocateSymbolAndIndustryList().subscribe((data: any) => {
    //   let dataSet=data;
    //   this.industryList=data.industries;
    //   this.symbolList=data.symbols;
    //   console.log('dataset--->>',dataSet);
    //   console.log('in dustry list--->>',this.industryList);
    //   console.log('in symbol list--->>',this.symbolList);
      
    //   })
  }

    //corporate data
    onChange2(fileInput2: any) {
      this.fileToupload2 = fileInput2.target.files['0']
    }
  /**** 
   * Bhavcopy Upload function
   * ***/
   onSubmit() {

    var formData2 = new FormData();
    // this.snackBar.open('File is Processing Please Wait.........');
    console.log('file----->>', this.fileToupload2);
    formData2.append('file', this.fileToupload2);
    if(this.fileToupload2!=undefined){
    this.fileUploadService.uploadCorporateActionExcel(formData2).subscribe((data: any) => {
      // let result=res;
      console.log('Corporate Posts------->>>>', data);

      if (data.statusCode == 200) {
        // this.snackBar.dismiss();
        this.snackBar.open('Corporate Data Uploaded.', '', {
          duration: 3000
        });
      }
     else if (data.statusCode == 404) {
        // this.snackBar.dismiss();
        this.snackBar.open('Please Choose File before proceeding.', '', {
          duration: 3000
        });
      }
      else if (data.statusCode == 409) {
        // this.snackBar.dismiss();
        this.snackBar.open('Data Exist.', '', {
          duration: 3000
        });
      }
      else {
        this.snackBar.open(`Opps Something Went Wrong !!`, '', {
          duration: 3000
        });
      }
      this.ngOnInit();
      // window.location.reload();

    });
  }else {
    this.snackBar.open(`Please Choose File before proceeding.`, '', {
      duration: 3000
    });
  }

  }


  onSubmitIndustry() {

    console.log('this.corporates--->>',this.corporates);
    let data =this.corporates.map(i=>{
      return {
        "symbol":(i.symbol).toLocaleUpperCase(),
        "ex_date":moment(i.ex_date).format('YYYY-MM-DD'),
        "adjustment_factor":i.adjustment_factor,
        "ca_type":i.ca_type,
        "future_status":0,
        "cash_status":0
      }
    })
    console.log('data ---------->>',data);
    this.industriesService.addCorporateAction({"data":data}).subscribe((post: any) => {
      console.log('post--->>',post);
      if(post.statusCode==200){
        this.snackBar.open('Data successfully!', '', {
          duration: 4000
        });
        this.ngOnInit();
      }else if(post.statusCode==409){
        this.snackBar.open(`Data Exist! You can't insert Dublicate Entry `, '', {
          duration: 4000
        });
        this.ngOnInit();
      }else{
        this.snackBar.open('Something Went Wrong', '', {
          duration: 3000
        });
      }
      })

  }
  seletedIndustry(i){
    this.selectedIndustry=i;
    console.log('form data---i--->>',i);
  }
  seletedSymbol(s){
    this.selectedSymbols=s;
    console.log('form data---s--->>',s);
  }
  // Add the corporates in an array
  addMore() {
    this.corporates.push({
      symbol: "",
      ex_date:"",
      adjustment_factor:null,
      ca_type:null
    });
  }

  // Delete Industry 
  removeIndustry(index) {
    this.corporates.splice(index, 1);
  }

 
  validateIndustries() {
    if (this.corporates.length > 1) {
      let uniqueNames = [];
      this.corporates = this.corporates.filter((i) => {
        i.symbol = i.symbol.trim();
        let index;
        if (i.symbol) {
          index = uniqueNames.indexOf(i.symbol);
          if (index == -1) {
            uniqueNames.push(i.symbol);
          }
        }
        return index == -1;
      });
      return this.corporates.length > 0;
    } else if (!this.corporates[0].symbol) {
      // TODO Show empty value error
      return false;
    }
  }


  createIndustries() {
    // Filter industries and remove empty values
    let isValid = this.validateIndustries();
    console.log("Valid *************", isValid);
    console.log("************* New Industries *************", this.corporates);
    let arr=this.corporates.map(item=>{return item.symbol;});
    let data={"symbol":arr};
    console.log('data--->>',data);
    if (isValid) { // At least 1 non empty industry
      this.industriesService.addIndustry(data).subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.snackBar.open('Industries created successfully!', '', {
            duration: 2000
          });

          this.router.navigate(['/housekeeping/industry/']);
        } else {
          this.snackBar.open('Something Went Wrong', '', {
            duration: 2000
          });
        }
      }, (error) => {
        console.log("API Error ", error);
        this.snackBar.open('Something Went Wrong', '', {
          duration: 2000
        });
      });
    }
  }

}
