import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  constructor() { }


  getPageNumbers(limit, total) {
    let pageNumbers = [];
    let count = Math.ceil(total / limit);
    for (let i = 1; i <= count; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }
}
