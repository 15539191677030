import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

/* File Upload Service */
uploadBhavCopyExcel(json) { //bhavcopy
    return this.http.post(environment.IMPORT_API + 'bhavcopy',json);
  }
uploadLotSizeExcel(json){  // lotsize
  return this.http.post(environment.IMPORT_API + 'lotSize',json);
}
/* File Upload Service */
uploadCorporateActionExcel(json) { //Corporate Action 
  return this.http.post(environment.IMPORT_API + 'uploadCorporateActionFile',json);
}
uploadVIXExcel(json){  // VIX File
  return this.http.post(environment.IMPORT_API + 'VIXReport',json);
}
uploadFIIExcel(json){  // FII File
  return this.http.post(environment.IMPORT_API + 'FIIReport',json);
}
uploadIVReportExcel(json){  // IV Report File
  return this.http.post(environment.IMPORT_API + 'IVReport',json);
}
uploadNseOIExcel(json){  // Combined OI Report File
  return this.http.post(environment.IMPORT_API + 'nseOpenInterest',json);
}
uploadFNOMarginExcel(json){  // FNO Margin Report File
  return this.http.post(environment.IMPORT_API + 'FNOMargin',json);
}
uploadCashMarketBhavcopyReportExcel(json){  // Cash Market Report File
  return this.http.post(environment.IMPORT_API + 'cashMarketBhavcopyReport',json);
}

/*************************Cron Activity**********************************/
startExecutionOnCron(){  // start execution
  return this.http.get(environment.CRON_API_URL + 'startExecution');
}

calculateOIChangesOnCron(){  // calculate oi
  return this.http.get(environment.CRON_API_URL + 'calculate-oi-changes');
}

clearAppRedisOnCron(){  //clear redis
  return this.http.get(environment.APP_API_URL + 'delete-redis');
}




}
