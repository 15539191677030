import { Component, OnInit, ViewChild } from '@angular/core';
import { DatacallsService } from 'src/app/datacalls.service';
import { MatPaginator, MatSort, MatSnackBar, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-data-market-pulse',
  templateUrl: './data-market-pulse.component.html',
  styleUrls: ['./data-market-pulse.component.scss'],
  providers:[DatacallsService]
})
export class DataMarketPulseComponent implements OnInit {
  
  displayedColumns: string[] = ['id', 'name', 'd1','d2','d3','d4','d5','d6','d7','d8','d9','d10','d11','d12','d13','d14','d15','d16','d17','d18','d19','d20','d21','d22','d23','d24','d25'];
  dataSource; 
  posts;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.firstPage();
    }
  }
  

  constructor(private DatacallsService:DatacallsService,private snackBar:MatSnackBar) { }

  ngOnInit() {

    // this.DatacallsService.view_marketpulse_data().subscribe(post=>{
    //   this.posts=post.result;
    //   console.log('posts data market pulse -=-=->>',this.posts);

    //   this.dataSource=new MatTableDataSource(this.posts);
    //   this.dataSource.paginator=this.paginator;
    //   this.dataSource.sort=this.sort;

    // })

  }

}
