import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { environment } from "../../../environments/environment.prod";
import { environment } from "../../../environments/environment";
import { AppService } from "../app.service";
@Injectable({
  providedIn: 'root'
})
export class AppApiService {

  constructor(private http: HttpClient, private appService: AppService) { }

  getSymbols() {
    return this.http.get(environment.COMMON_API_URL + 'getSymbolListFromBhavcopy');
  }

  getStrikePrices(filterParams) {
    let formData = this.appService.getFormData(filterParams);
    return this.http.post(environment.COMMON_API_URL + 'getStrikePrice', formData);
  }
}
