import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DatacallsService } from '../datacalls.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatePipe } from '@angular/common';
// import * as moment from 'moment';


@Component({
  selector: 'app-option-scores',
  templateUrl: './option-scores.component.html',
  styleUrls: ['./option-scores.component.scss'],
  providers: [DatacallsService, DatePipe]
})
export class OptionScoresComponent implements OnInit {

  displayedColumns = ['id', 'invoiceno', 'invoice', 'packing', 'status', 'date', 'amount', 'retailer', 'asm'];
  // , 'logistic', 'docket', 'edit', 'last_update'
  filterForm:FormGroup;
  myDate = new Date();

  constructor(private _fb: FormBuilder, public datacalls: DatacallsService, public snackBar: MatSnackBar, private Router: Router, public dialog: MatDialog, @Inject(DOCUMENT) doc: any, private datePipe: DatePipe) {
    this.filterForm = this._fb.group({

      stockSymbol: [''],
      start_date:['']

    });
    //this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
  }

  onSubmit() {
    
  }

}
