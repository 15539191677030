// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* ******************* Connect With Dev Server ******************************* */

// let END_POINT_URL="http://localhost:3002";
let END_POINT_URL="https://admin-api.richmonks.co.in";

export const environment = {
  production: false,
  APP_API_URL: "https://api.richmonks.in/api/",

  ADMIN_API_URL: END_POINT_URL+"/api/app/",
  ADMIN_API_URL_2: END_POINT_URL+"/api/app/",
  EXPORT_API: END_POINT_URL+"/api/exportData/",
  IMPORT_API: END_POINT_URL+"/api/fileupload/",
  EXPORT_API_2: END_POINT_URL+"/api/dataExport/",
  UPLOAD_API: "",

  // CRON_API_URL: "http://localhost:3000/api/app/",
  CRON_API_URL: "https://cron.richmonks.co.in/api/app/",

  LOGIN_API_URL: END_POINT_URL+"/api/login/",
  ALGO_API_URL: END_POINT_URL+"/api/algo/",
  COMMON_API_URL: END_POINT_URL+"/api/common/",
  DASHBOARD_API_URL: END_POINT_URL+"/api/dashboard/",
  CA_API_URL: END_POINT_URL+"/api/corporate-action/",
  INDUSTRY_API_URL: END_POINT_URL+"/api/industry/",
  USER_API_URL: END_POINT_URL+"/api/users/",
  NIFTY50_API_URL: END_POINT_URL+"/api/nifty50/",
  HOLIDAY_API_URL: END_POINT_URL+"/api/holiday/",
  REPORT_API_URL: END_POINT_URL+"/api/reports/",
  RMNOTES_API_URL: END_POINT_URL+"/api/rm-notes/",
};

/* ******************* Connect With Live Server ******************************* */
// export const environment = {
//   production: false,
//   APP_API_URL: "https://api.richmonks.in/api/",

//   ADMIN_API_URL: "https://admin-api.richmonks.co.in/api/app/",
//   ADMIN_API_URL_2: "https://admin-api.richmonks.co.in/api/app/",
//   EXPORT_API: "https://admin-api.richmonks.co.in/api/exportData/",
//   IMPORT_API: "https://admin-api.richmonks.co.in/api/fileupload/",
//   EXPORT_API_2: "https://admin-api.richmonks.co.in/api/dataExport/",
//   UPLOAD_API: "",

//   // CRON_API_URL: "http://localhost:3000/api/app/",
//   CRON_API_URL: "https://cron.richmonks.co.in/api/app/",

//   LOGIN_API_URL: END_POINT_URL+"/api/login/",
//   ALGO_API_URL: END_POINT_URL+"/api/algo/",
//   COMMON_API_URL: END_POINT_URL+"/api/common/",
//   DASHBOARD_API_URL: END_POINT_URL+"/api/dashboard/",
//   CA_API_URL: END_POINT_URL+"/api/corporate-action/",
//   INDUSTRY_API_URL: END_POINT_URL+"/api/industry/",
//   NIFTY50_API_URL: END_POINT_URL+"/api/nifty50/",
//   HOLIDAY_API_URL: END_POINT_URL+"/api/holiday/",
//   REPORT_API_URL: END_POINT_URL+"/api/report/",
//   RMNOTES_API_URL: END_POINT_URL+"/api/rm-notes/",
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
