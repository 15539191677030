import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  /* ---------- IV REPORTS ---------- */

  getIVReport(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewIVReport', filters);
  }

  exportIVReport() {
    return this.http.post(environment.EXPORT_API_2 + 'exportIVReportData', {});
  }

  /* ------------- OPTIONS REPORTS ----------- */

  getOptionsReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewOptionsData', filters);
  }

  exportOptionsReportsWithFilters(filters) {
    return this.http.post(environment.EXPORT_API_2 + 'exportOptionData', filters);
  }


  /* -------------------- Script wise Reports -------------- */

  getScriptwiseReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewMISReportData', filters);
  }

  exportScriptwiseReportsWithFilters(filters) {
    return this.http.post(environment.EXPORT_API_2 + 'exportHistoricalData', filters);
  }

  getHistoricalReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewHistoricalData', filters);
  }

  /* -------------------- OI  Reports -------------- */

  getOIReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewOIReportData', filters);
  }

  exportOIReportWithFilters(filters) {
    return this.http.post(environment.EXPORT_API_2 + 'exportOIReportData', filters);
  }

  /* -------------------- OI  Reports -------------- */

  getPlReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewPLReportData', filters);
  }
  /* -------------------- rank  Reports -------------- */

  getRankReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewRankReportData', filters);
  }
  /* -------------------- option Reports -------------- */

  getOptionReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewOptionReportData', filters);
  }

  /* -------------------- call put  Reports -------------- */

  getCallPutReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewCallPutReportData', filters);
  }

  /* -------------------- mom  Reports -------------- */
  getMOMReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewMOMReportData', filters);
  }

  /* -------------------- mom  Reports -------------- */
  viewOIComparisonDataWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewOIComparisonData', filters);
  }

  /* -------------------- moving Avg Reports -------------- */
  getMovingAVGDataWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewMovingAVGData', filters);
  }

  getDailyReportDataWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewDailyDataData', filters);
  }

  /* -------------------- MR Reports -------------- */
  getviewMRReportDataWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewMRReportData', filters);
  }

  /* -------------------- Highest Weekly Gainer Reports -------------- */
  getHighestWeeklyGainerOIDataWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewHighestWeeklyGainerOIData', filters);
  }


  /* -------------------- getFutureOIReportDataWithFilters Reports -------------- */
  getFutureOIReportDataWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewFutureOIReportData', filters);
  }

  /* -------------------- adding most oi  Reports -------------- */

  getAddingOIReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewAddingMostOIReportData', filters);
  }

  /* -------------------- viewOptionReport1Data  Reports -------------- */

  getviewOptionReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewOptionReport1Data', filters);
  }

  /* -------------------- viewOptionReport2Data  Reports -------------- */

  getviewOptionReport2WithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewOptionReport2Data', filters);
  }

  /* -------------------- current Dates -------------- */

  getCurrentData() {
    return this.http.get(environment.COMMON_API_URL + 'getCurrentData');
  }



  /* -------------------- strike_pr -------------- */

  getSymbolWiseStrikePrice(filters) {
    return this.http.post(environment.COMMON_API_URL + 'getSymbolWiseStrikePrice', filters);
  }

  /* -------------------- saved  report Dates -------------- */

  getSavedReportData() {
    return this.http.get(environment.REPORT_API_URL + 'viewSaveFutureReportData');
  }

  /* ---------------------- MIS Report ------------------- */

  getMISReportWithFilters(filters) {
    // let res=this.http.post(environment.REPORT_API_URL + 'viewMISReportData', filters)
    return this.http.post(environment.REPORT_API_URL + 'viewMISReportData', filters);
    //   console.log('api hit ',res);
    //  return res;
  }

  getDailyReportWithFilters(filters) {
    console.log('api hit ');
    let res = this.http.post(environment.REPORT_API_URL + 'viewDailyDataData', filters);
    console.log('api hit ', res);
    return res;
  }

  /* ---------------------- Future Master Report ------------------- */

  getFutureMasterReportWithFilters(filters) {
    return this.http.post(environment.REPORT_API_URL + 'viewFutureMasterReportData', filters);
  }

  // getFutureMasterReportWithFilters(filters) {
  //   return this.http.post('http://localhost:3002/api/app/viewFutureMasterReportData', filters);
  // }

  addReportsData(filters) {
    return this.http.post(environment.REPORT_API_URL + 'saveFutureReportData', filters);
  }

  deleteFutureMasterSavedReportData(filters) {
    return this.http.post(environment.REPORT_API_URL + 'deleteSaveFutureReportData', filters);
  }

  exportMisReport(filters) {
    // let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // headers.append('responseType', 'arrayBuffer');
    // let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'arrayBuffer'
    });
    let options = {
      headers: headers
    }
    return this.http.post(environment.EXPORT_API_2 + 'exportMISReportData', filters, options);
  }


}
