import { Component, OnInit, ViewChild } from '@angular/core';
import { DatacallsService } from '../../datacalls.service';
import { MatSnackBar, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-lotsize',
  templateUrl: './lotsize.component.html',
  styleUrls: ['./lotsize.component.scss'],
  providers: [DatacallsService]
})
export class LotsizeComponent implements OnInit {
  posts;
  posts1;
  fileToupload;

  displayedColumns: string[] = ['position', 'symbol', 'lot_size', 'date','edit','delete'];
  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  color1;modification_status1;
  constructor(private _DatacallsService: DatacallsService,public snackBar: MatSnackBar) { }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  ngOnInit() {

    // this._DatacallsService.viewfinsparclotsize(null,null).subscribe(posts => {
    //   this.posts = posts.result

    //   console.log('posts data ----> ',this.posts)
    //   this.dataSource = new MatTableDataSource(this.posts);
    //   this.dataSource.paginator = this.paginator
    //   this.dataSource.sort = this.sort
    // });

    
    
    //   this._DatacallsService.view_finsparc_lotchange(null).subscribe(posts =>{
    //   this.posts1 = posts.result;
    //   this.color1=this.posts1[0].color;
    //   this.modification_status1=this.posts1[0].modification_status;
    //   console.log('posts lot change data ', this.posts1);
    //   console.log('posts data color ', this.posts1[0].color);
    //   console.log('posts data modification_status', this.posts1[0].modification_status);
 
    //   // this.dataSource = new MatTableDataSource(this.posts1);
    //   // this.dataSource.paginator = this.paginator
    //   // this.dataSource.sort = this.sort

    // }) 

  }
  // getPageSize(){

  //   this._DatacallsService.viewfinsparclotsize(this.dataSource.paginator.pageIndex, this.dataSource.paginator.pageSize).subscribe(posts => {
  //     this.posts = posts.result
  //     this.dataSource = new MatTableDataSource(this.posts);
  //     this.dataSource.paginator = this.paginator
  //     this.dataSource.sort = this.sort
  //   });
  // }


  
  onChange(fileInput: any) {
    this.fileToupload = fileInput.target.files['0']
  }


  onDelete(id){

    console.log("id-=-=-=-=-=->",id)
    var data={
      "id":id,
      "table_id":"symbol",
      "table":"finsparc_lotsize"
    }
    console.log('dataatatata---=----=-->>',data);
    // this._DatacallsService.universalDeleteForLocalAndAWS1(data).subscribe(posts => {
    //   // this.posts = posts.result;
    //   this.snackBar.open('Notes Deleted Successfully', '', {
    //     duration: 2000
    //   });
    //   this.ngOnInit();
    // });
    
}


  onSubmit() {

    var formData = new FormData();

    formData.append('file1', this.fileToupload);
    this.snackBar.open('Uploading..........  ', '', {
      duration: 2000
    });
    // this._DatacallsService.uploadFinsparcLotSize(formData).subscribe(posts => {
    //   console.log('data------>',posts)
    //   this.snackBar.open('Excel File is Uploading..........  ', '', {
    //     duration: 2000
    //   });

    //   if (posts['Status'] == 200) {
    //     this.snackBar.open('Excel Uploaded Successfully', '', {
    //       duration: 2000
    //     });
    //     this.ngOnInit();
    //   }
    //   else if(posts.result[0].status==203) 
    //   {
    //       this.snackBar.open('Column Name Incorrect', '', {
    //       duration: 2000 
    //     });
    //   }
    //   else if(posts.result[0].status==404) 
    //   {
    //       this.snackBar.open('Please Choose File First', '', {
    //       duration: 2000
    //     });
    //   }
    //   else {
    //     this.snackBar.open('Please Try Again', '', {
    //       duration: 2000
    //     });
    //   }
    // });


  }

}
