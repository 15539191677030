import { Component, OnInit, ViewChild } from '@angular/core';
import { DatacallsService } from '../../datacalls.service';
import { MatSnackBar, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];


@Component({
  selector: 'app-bhavcopy',
  templateUrl: './bhavcopy.component.html',
  styleUrls: ['./bhavcopy.component.scss'],
  providers: [DatacallsService]
})
export class BhavcopyComponent implements OnInit { 

  fileToupload;
  posts;
  // displayedColumns: string[] = ['position', 'name', 'symbol', 'cdate', 'expiry_dt', 'strike_pr', 'option_type', 'open', 'high', 'low', 'close', 'settle_pr', 'contracts', 'val_inlakh', 'open_int', 'chg_in_oi','update'];
  // displayedColumns: string[] = ['position','symbol', 'cdate','update'];
  displayedColumns: string[] = ['id', 'name', 'd14','d1','d3','d4','d5','d6','d7','d8','d9','d2','d10','d11','d12','d13','d15'];
  dataSource;
   @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private _DatacallsService: DatacallsService,public snackBar: MatSnackBar) { }
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit() { 

    // this._DatacallsService.view_bhavcopy_data().subscribe(post=>{
    //   this.posts=post.result;
    //   console.log('posts data-=-=->>',this.posts);

    //   this.dataSource=new MatTableDataSource(this.posts);
    //   this.dataSource.paginator=this.paginator;
    //   this.dataSource.sort=this.sort;

    // })
  }

  onChange(fileInput: any) {
    this.fileToupload = fileInput.target.files['0']
  }

  onSubmit() {

    var formData = new FormData();

    this.snackBar.open('file Upload Succeeded Please Wait.........', '', {
      duration: 2000,
      announcementMessage:"started",
      horizontalPosition:  'center',
      verticalPosition: 'top'
    });
 
    formData.append('file1', this.fileToupload);
    // this._DatacallsService.uploadBhavCopyExcel(formData).subscribe(posts => {
    //   console.log('Posted Bhavcopy response--->>',posts);
    //   if (posts.result[0].status==201) {
    //     this.snackBar.open('You Can not upload same Data Again', '', {
    //       duration: 4000
    //     });
    //     this.ngOnInit();
    //   } 
    //   else if(posts.result[0].status==404) 
    //   {
    //       this.snackBar.open('Please Choose File First', '', {
    //       duration: 2000
    //     });
    //   }
    //   else if(posts.result[0].status==203) 
    //   {
    //       this.snackBar.open('Column Name Incorrect or wrong file choosen', '', {
    //       duration: 2000 
    //     });
    //   }
    //   else if(posts.status==202) 
    //   {
    //       this.snackBar.open('OPEN_INT Should be greater than or equal to zero', '', {
    //       duration: 2000
    //     });
    //   }
    //   else
    //   {
    //       this.snackBar.open('Excel Uploded Successfully', '', {
    //       duration: 8000
    //     });
    //   }
    // });

    // this.snackBar.open('Completed..', '', {
    //   duration: 200
    // });
    this.ngOnInit();
  }

  

} 
