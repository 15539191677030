import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RmnotesService {

  constructor(private http: HttpClient) { }

  addRMNotesData(json) {
    return this.http.post(environment.RMNOTES_API_URL + 'addRMNotesData', json);
  }

  viewFinsparcEditorsNotes(json) {
    let res=this.http.post(environment.RMNOTES_API_URL+ 'viewRMNotes', json);
    console.log('api hit ',res);
    return res;
  }

  viewDailyDataData(json) {
    let res=this.http.post(environment.REPORT_API_URL+ 'viewDailyDataData', json);
    console.log('api hit ',res);
    return res;
  }

}
