import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndustriesService {

  constructor(private http: HttpClient) { }

  /* ------------------------ Get Industries ---------------------- */
  getIndustries() {
    return this.http.get(environment.INDUSTRY_API_URL + 'viewIndustry');
  }

  viewNifty50() {
    return this.http.get(environment.NIFTY50_API_URL + 'viewNifty50');
  }

  viewNifty50New(id) {
    return this.http.get(environment.NIFTY50_API_URL + 'viewNifty50/'+id);
  }
  
  addNifty50Stock(json) {
    return this.http.post(environment.NIFTY50_API_URL + 'addNifty50Stock', json);
  }

  deleteNifty50(json) {
    return this.http.post(environment.NIFTY50_API_URL + 'deleteNifty50', json);  
  }


  getCorporateData(id,status) {
    return this.http.get(environment.CA_API_URL + 'viewCorporateAction/'+id+'/'+status);
  }

  getIndustrySymbol(id) {
    return this.http.get(environment.INDUSTRY_API_URL + 'viewIndustrySymbol/' + id);
  }

  allocateSymbol(json) {
    return this.http.post(environment.INDUSTRY_API_URL + 'allocateSymbol',json);
  }
  addCorporateAction(json) {
    return this.http.post(environment.INDUSTRY_API_URL + 'addCorporateAction',json);
  }
  unallocateSymbol(json) {
    return this.http.post(environment.INDUSTRY_API_URL + 'unallocateSymbol',json);
  }
  //get unallocated symbols and industry
  getUnallocateSymbolAndIndustryList() {
    return this.http.get(environment.INDUSTRY_API_URL + 'viewUnallocatedIndustryAndSymbol');
  }

  // Delete Industry
  deleteIndustry(json) {
    return this.http.post(environment.INDUSTRY_API_URL + 'deleteIndustry', json);
  }
  // deleteCorporateAction
  deleteCorporateAction(json) {
    return this.http.post(environment.CA_API_URL + 'deleteCorporateAction', json);
  }
  // Delete Symbols From Industry
  deleteIndustrySymbol(json) {
    return this.http.post(environment.INDUSTRY_API_URL + 'deleteIndustrySymbol', json);
  }

  // Add Industry
  addIndustry(json) {
    return this.http.post(environment.INDUSTRY_API_URL + 'addIndustry', json);
  }

  // Add Symbols to Industry
  addSymbolsToIndustry(json) {
    return this.http.post(environment.INDUSTRY_API_URL + 'addIndustrySymbol', json);
  }
}
