import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions, ResponseContentType } from '@angular/http';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Rx';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
// import { environment } from 'src/environments/environment';
import { environment } from '../environments/environment';

@Injectable()
export class DatacallsService {

  /* Https */
  // ip = 'http://localhost:3002';     //Dev server 
  ip = 'https://admin-api.richmonks.co.in';     //Live server

  constructor(private http: Http) { }

  verifyLogin(json) {
    return this.http.post(environment.LOGIN_API_URL + 'getUser', json)
      .map(res => res.json());
  }

  // viewFearAndGreedIndex(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'mmi/viewMMIData', json)
  //     .map(res => res.json());
  // }
  // updateFearAndGreedIndexAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'mmi/updateMMIData', json)
  //     .map(res => res.json());
  // }

  // viewRankAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'rank/viewRankAlgo', json)
  //     .map(res => res.json());
  // }

  // getImportInfo
  // getImportInfo() {
  //   return this.http.get(environment.DASHBOARD_API_URL + 'getImportInfo')
  //     .map(res => res.json());
  // }

  // updateRankAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'rank/updateRankAlgo', json)
  //     .map(res => res.json());
  // }

  // viewMRCalculatorAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'mr-calculator/viewMRCalculator', json)
  //     .map(res => res.json());
  // }

  // UpdateMRCalculatorAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'mr-calculator/UpdateMRCalculator', json)
  //     .map(res => res.json());
  // }

  // viewTriggerPointAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'trigger-point/viewTriggerPoint', json)
  //     .map(res => res.json());
  // }
  // updateTriggerPointAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'trigger-point/updateTriggerPoint', json)
  //     .map(res => res.json());
  // }

  // updateFutureScoreGroupAAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'future-option/updateFutureScoreGroupA', json)
  //     .map(res => res.json());
  // }

  // updateFutureScoreGroupBAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'future-option/updateFutureScoreGroupB', json)
  //     .map(res => res.json());
  // }

  // viewScoreGroupPointsAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'future-option/viewScoreGroupPoints', json)
  //     .map(res => res.json());
  // }

  // updateScoreGroupPointsDataAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'future-option/updateScoreGroupPointsData', json)
  //     .map(res => res.json());
  // }

  // viewFutureScoreGroupAAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'future-option/viewFutureScoreGroupA', json)
  //     .map(res => res.json());
  // }

  // viewFutureScoreGroupBAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'future-option/viewFutureScoreGroupB', json)
  //     .map(res => res.json());
  // }

  // viewOptionFutreScoreAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'future-option/viewOptionFutreScore', json)
  //     .map(res => res.json());
  // }
  // updateOptionFutreScoreAlgo(json) {
  //   return this.http.post(environment.ALGO_API_URL + 'future-option/updateOptionFutreScore', json)
  //     .map(res => res.json());
  // }

  // addRMNotesData(json) {
  //   return this.http.post(environment.RMNOTES_API_URL + 'addRMNotesData', json)
  //     .map(res => res.json());
  // }

  // viewFinsparcEditorsNotes(json) {
  //   return this.http.post(environment.RMNOTES_API_URL+ 'viewRMNotes', json)
  //     .map(res => res.json());
  // }

  // viewSymbolList(json) {
  //   return this.http.post(environment.COMMON_API_URL + 'viewSymbolList', json)
  //     .map(res => res.json());
  // }

  // viewSymbolDetails(json) {
  //   return this.http.post(environment.COMMON_API_URL + 'viewSymbolDetails', json)
  //     .map(res => res.json());
  // }

  // viewNifty50(id) {
  //   return this.http.get(environment.NIFTY50_API_URL + 'viewNifty50/', id)
  //     .map(res => res.json());
  // }

  // getAllSymbolList() {
  //   return this.http.get(environment.COMMON_API_URL + 'getAllSymbolList')
  //     .map(res => res.json());
  // }

  // addNifty50Stock(json) {
  //   return this.http.post(environment.NIFTY50_API_URL + 'addNifty50Stock', json)
  //     .map(res => res.json());
  // }

  // deleteNifty50(json) {
  //   return this.http.post(environment.NIFTY50_API_URL + 'deleteNifty50', json)
  //     .map(res => res.json());
  // }

  exportMisReport(filters): Observable<File> {

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportMISReportData/', filters, options)
      .map(res => res.json());
  }

  exportFutureMasterReportData(filters): Observable<File> {

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportFutureMasterReportData/', filters, options)
      .map(res => res.json());
  }
  exportOIReportWithFilters(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportOIReportData/', filters, options)
      .map(res => res.json());
  }

  exportHistoricalReportWithFilters(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportHistoricalData/', filters, options)
      .map(res => res.json());
  }

  exportPLReportWithFilters(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportPLReportData/', filters, options)
      .map(res => res.json());
  }
  exportRankReportWithFilters(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportRankReportData/', filters, options)
      .map(res => res.json());
  }
  exportMOMReportDataWithFilters(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportMOMReportData/', filters, options)
      .map(res => res.json());
  }
  exportAddingMostOIWithFilters(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportAddingMostOIData/', filters, options)
      .map(res => res.json());
  }
  exportOptionReport2Data(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportOptionReport2Data/', filters, options)
      .map(res => res.json());
  }
  exportOptionReport1Data(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportOptionReport1Data/', filters, options)
      .map(res => res.json());
  }

  exportMovingAVGDataWithFilters(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportMovingAVGData/', filters, options)
      .map(res => res.json());
  }

  exportMRReportDataWithFilters(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportMRReportData/', filters, options)
      .map(res => res.json());
  }

  exportBhavcopyData(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportBhavCopyData/', filters, options)
      .map(res => res.json());
  }

  bhavCopyData(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/bhavCopyData/', filters, options)
      .map(res => res.json());
  }

  exportLiveBhavcopyData(): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.get(this.ip + '/api/dataExport/exportLiveBhavCopyData/',options)
      .map(res => res.json());
  }
  exportIVReportData(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportIVReportData/', filters, options)
      .map(res => res.json());
  }

  exportVIXReportData(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportVIXReportData/', filters, options)
      .map(res => res.json());
  }

  exportFIIReportData(filters): Observable<File> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.post(this.ip + '/api/dataExport/exportFIIReportData/', filters, options)
      .map(res => res.json());
  }
  /****************************** below api's are old ************************************************/
 
  // scheduler_info
  scheduler_info() {
    return this.http.get(this.ip + '/scheduler_info')
      .map(res => res.json());
  }
}
