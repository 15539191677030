import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { BhavcopyComponent } from './pages/bhavcopy/bhavcopy.component';
import { LotsizeComponent } from './pages/lotsize/lotsize.component';

import { HoildaysComponent } from './pages/hoildays/hoildays.component';
import { EditorNotesComponent } from './editor-notes/editor-notes.component';
import { AddnotesComponent } from './addnotes/addnotes.component';
import { AuthguardGuard } from './authguard.guard';
import { AddScoreComponent } from './pages/add-score/add-score.component';
import { AddLotsizeComponent } from './pages/add-lotsize/add-lotsize.component';
import { IvrReportComponent } from './pages/ivr-report/ivr-report.component';
import { AddSettlementComponent } from './pages/add-settlement/add-settlement.component';
import { DataCurrentBhavcopyComponent } from './pages/data-current-bhavcopy/data-current-bhavcopy.component';
import { DataMarketPulseComponent } from './pages/data-market-pulse/data-market-pulse.component';
import { AddLotChangeComponent } from './pages/add-lot-change/add-lot-change.component';
import { AddNifty50StockComponent } from './pages/add-nifty50-stock/add-nifty50-stock.component';
import { AddIndustryComponent } from './pages/add-industry/add-industry.component';
import { IndustrySymbolComponent } from './pages/housekeeping/industry-symbol/industry-symbol.component';
import { AddCorporateActionComponent } from './pages/add-corporate-action/add-corporate-action.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';



export const routes: Routes = [
    { path: '', loadChildren: './pages/login/login.module#LoginModule' },
    { 
        path: '', 
        component: PagesComponent, children: [
            { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardModule', data: { breadcrumb: 'Dashboard' } },
            // { path: 'users', loadChildren: './pages/users/users.module#UsersModule', data: { breadcrumb: 'Users' } }, 
            { path: 'housekeeping', loadChildren: './pages/housekeeping/housekeeping.module#HousekeepingModule', data: { breadcrumb: 'House Keeping' } },
            { path: 'user-details', loadChildren: './pages/user-details/user-details.module#UserDetailsModule', data: { breadcrumb: 'User Details' } },
            { path: 'user-details/admin-user', loadChildren: './pages/user-details/user-details.module#UserDetailsModule', data: { breadcrumb: 'User Details' } },
            { path: 'user-details/add-user', loadChildren: './pages/user-details/user-details.module#UserDetailsModule', data: { breadcrumb: 'Add User' } },
            { path: 'user-details/edit-user/:id?', loadChildren: './pages/user-details/user-details.module#UserDetailsModule', data: { breadcrumb: 'Edit User' } },
        

            { path: 'data-processing', loadChildren: './pages/data-processing/data-processing.module#DataProcessingModule', data: { breadcrumb: 'Data Processing' } },

            { path: 'icons', loadChildren: './pages/icons/icons.module#IconsModule', data: { breadcrumb: 'Icons' } },
            { path: 'algorithm', loadChildren: './pages/algorithm/algorithm.module#AlgorithmModule', data: { breadcrumb: 'Algorithm' } },
            { path: 'download', loadChildren: './pages/download/download.module#DownloadModule', data: { breadcrumb: 'Downloads' } },
            { path: 'form-controls', loadChildren: './pages/form-controls/form-controls.module#FormControlsModule', data: { breadcrumb: 'Form Controls' } },
            {path:'addeditors',component:AddnotesComponent},
            {path:'editornotes',component:EditorNotesComponent},
            {path:'edit-editors/:id',component:EditorNotesComponent},
            {path:'industry-symbol/:id',component:IndustrySymbolComponent},
            { path: 'bhavcopy', component:BhavcopyComponent},
            { path: 'lotsize', component:LotsizeComponent},
            { path: 'holiday', component: HoildaysComponent },
            { path:'add-score',component:AddScoreComponent},
            { path:'edit-score/:finsparc_score_id',component:AddScoreComponent},
            
            { path:'add-lotsize',component:AddLotsizeComponent},
            { path:'add-settlement',component:AddSettlementComponent},
            { path:'edit-settlement/:finsparc_settlementdates_id',component:AddSettlementComponent},
            { path:'edit-lotsize/:finsparc_lotsize_id',component:AddLotsizeComponent},
            { path:'ivr-report',component:IvrReportComponent},

            { path:'data-current-bhavcopy',component:DataCurrentBhavcopyComponent},
            { path:'data-market-pulse',component:DataMarketPulseComponent },
            { path:'market-pulse',loadChildren:'./pages/market-pulse/market-pulse.module#MarketPulseModule',data:{ breadcrumb:'Market Pulse' }},
// ****************************************************************
             { path:'lot-change', loadChildren:'./pages/lot-change/lot-change.module#LotChangeModule',data:{breadcrumb:'Lot Change'}},   
             { path:'add-lot-change',component:AddLotChangeComponent},
             { path:'edit-lot-change/:id',component:AddLotChangeComponent},
             { path:'industry',loadChildren:'./pages/industry/industry.module#IndustryModule',data:{breadcrumb:'Industry'}},
             { path:'add-industry/:id',component:AddIndustryComponent},
             { path:'add-corporate-action/:id',component:AddCorporateActionComponent},
            //  { path:'edit-industry/:id',component:AddIndustryComponent},
             { path:'add-nifty50_stock/:id',component:AddNifty50StockComponent},
             { path:'edit-nifty50_stock/:id',component:AddNifty50StockComponent},

             {path:'unauthorized',component:UnauthorizedComponent},             
             { path: 'reports', loadChildren: './pages/reports/reports.module#ReportsModule', data: { breadcrumb: 'Reports' } },
            //  { path: 'backtesting-reports', loadChildren: './pages/backtesting-reports/backtesting-reports.module#BacktestingReportsModule', data: { breadcrumb: 'Back Testing Reports' } },
            //  { path: 'backtesting-reports', loadChildren: './pages/backtesting-reports/backtesting-reports.module#BacktestingReportsModule', data: { breadcrumb: 'Reports' }},

             { path: 'options', loadChildren: './pages/reports/options/options.module#OptionsModule', data: { breadcrumb: 'Option Reports' } },
// ****************************************************************
            { path: 'icons', loadChildren: './pages/icons/icons.module#IconsModule', data: { breadcrumb: 'Material Icons' } },
            { path: 'schedule', loadChildren: './pages/schedule/schedule.module#ScheduleModule', data: { breadcrumb: 'Schedule' } },
            { path: 'dynamic-menu', loadChildren: './pages/dynamic-menu/dynamic-menu.module#DynamicMenuModule', data: { breadcrumb: 'Dynamic Menu' }  },          
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } }
        ],canActivate : [AuthguardGuard]
    },
    { path: 'landing', loadChildren: './pages/landing/landing.module#LandingModule' ,canActivate : [AuthguardGuard]},

    { path: 'register', loadChildren: './pages/register/register.module#RegisterModule',canActivate:[AuthguardGuard] },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } ,canActivate:[AuthguardGuard]},
    { path: '**', component: NotFoundComponent ,canActivate:[AuthguardGuard]}
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
   preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
   useHash: true
});