import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  viewSymbolList(json) {
    return this.http.post(environment.COMMON_API_URL + 'viewSymbolList', json);
  }

  viewSymbolDetails(json) {
    return this.http.post(environment.COMMON_API_URL + 'viewSymbolDetails', json);
  }

  getAllSymbolList() {
    return this.http.get(environment.COMMON_API_URL + 'getAllSymbolList');
  }

}
