import { Component, OnInit, ViewChild } from '@angular/core';
import { DatacallsService } from '../../datacalls.service';
import { MatSnackBar, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'app-hoildays',
  templateUrl: './hoildays.component.html',
  styleUrls: ['./hoildays.component.scss'],
  providers: [DatacallsService]
})
export class HoildaysComponent implements OnInit {


  posts;
  fileToupload;

  displayedColumns: string[] = ['position', 'date', 'day', 'desc'];
  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _DatacallsService: DatacallsService, public snackBar: MatSnackBar) { }

  ngOnInit() {

    // this._DatacallsService.viewfinsparchoilday().subscribe(posts => {
    //   this.posts = posts.result
    //   console.log("posts----=->",this.posts);
    //   this.dataSource = new MatTableDataSource(this.posts);
    //   this.dataSource.paginator = this.paginator
    //   this.dataSource.sort = this.sort
    // });

  }
  // getPageSize(){

  //   this._DatacallsService.viewfinsparclotsize(this.dataSource.paginator.pageIndex, this.dataSource.paginator.pageSize).subscribe(posts => {
  //     this.posts = posts.result
  //     this.dataSource = new MatTableDataSource(this.posts);
  //     this.dataSource.paginator = this.paginator
  //     this.dataSource.sort = this.sort
  //   });
  // }



  onChange(fileInput: any) {
    this.fileToupload = fileInput.target.files['0']
  }

  onSubmit() {

    var formData = new FormData();

    formData.append('file1', this.fileToupload);
    // this._DatacallsService.uploadFinsparcHoildays(formData).subscribe(posts => {

    //   if (posts['Status'] == 200) {
    //     this.snackBar.open('Excel Uploded Successfully', '', {
    //       duration: 2000
    //     });
    //     this.ngOnInit();
    //   }
    //   else {
    //     this.snackBar.open('Please Try Again', '', {
    //       duration: 2000
    //     });
    //   }
    // });


  }

}
