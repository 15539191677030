import { Component, OnInit, ViewChild } from '@angular/core';
import { DatacallsService } from 'src/app/datacalls.service';
import { MatSnackBar, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DataSource } from '@angular/cdk/table';

@Component({
  selector: 'app-data-current-bhavcopy',
  templateUrl: './data-current-bhavcopy.component.html',
  styleUrls: ['./data-current-bhavcopy.component.scss'],
  providers: [DatacallsService]

})
export class DataCurrentBhavcopyComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'd1','d3','d4','d5','d6','d7','d8','d9','d2','d10','d11','d12','d13','d14','d15'];
  dataSource;
posts;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.firstPage();
    }
  }
  
  constructor(private DatacallsService: DatacallsService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    // this.DatacallsService.view_bhavcopy_data().subscribe(post=>{
    //   this.posts=post.result;
    //   console.log('posts data-=-=->>',this.posts);

    //   this.dataSource=new MatTableDataSource(this.posts);
    //   this.dataSource.paginator=this.paginator;
    //   this.dataSource.sort=this.sort;

    // })
  
  }

}
