import { Menu } from './menu.model';

export const verticalMenuItems = [ 
    new Menu (1, 'Dashboard', '/dashboard', null, 'dashboard', null, false, 0),
    
    new Menu (2, 'House Keeping', '/housekeeping', null, 'supervisor_account', null, true, 0),
    // new Menu(4, 'Lot Size', '/housekeeping/lots', null, 'line_weight', null, false, 3),
    // new Menu(5, 'Lot Change', '/housekeeping/lot-change', null, 'compare_arrows', null, false, 3),
    new Menu(3,'Corporate Action','/housekeeping/corporate-action',null, 'timeline', null, false, 2),
    new Menu(4,'Industry','/housekeeping/industry',null, 'business', null, false, 2),
    new Menu(5,'Nifty50 Stock','/housekeeping/nifty50-stock',null, 'trending_up', null, false, 2),

    new Menu (6, 'Data Processing', '/data-processing', null, 'donut_large', null, true, 0),
    // new Menu(11, 'Auto Run', '/data-processing/auto-run', null, 'event', null, false, 4),
    new Menu(7, 'Manual Run', '/data-processing/manual-run', null, 'event', null, false, 6),

    new Menu (8, 'Algorithm', '/algorithm', null, 'spellcheck', null, true, 0),
    new Menu(9, 'Stocks Score Calculator', '/algorithm/score-calculator', null, 'data_thresholding', null, false, 8),
    new Menu(10, 'Indices Score Calculator', '/algorithm/score-calculator-indices', null, 'data_thresholding', null, false, 8),
    // new Menu(11, 'dma', '/algorithm/dma', null, 'event', null, false, 8),
    new Menu(12, 'Rank Calculator', '/algorithm/rank', null, 'data_exploration', null, false, 8),
    new Menu(13, 'MR Calculator', '/algorithm/mr-calculator', null, 'troubleshoot', null, false, 8),
    new Menu(14, 'Trigger/Breakout Point', '/algorithm/trigger-point-and-breakout-point', null, 'trending_up', null, false, 8),
    // new Menu(7, 'GRB Calculator', '/algorithm/grb', null, 'event', null, false, 16),
    new Menu(15, 'Fear and Greed', '/algorithm/fear-and-greed', null, 'unfold_more_double', null, false, 8),
 
    new Menu(16, 'Downloads', '/download', null, 'save_alt', null, true, 0),
    new Menu(17, 'NSE Data', '/download/manual-downloads', null, 'file_download', null, false, 16),
    // new Menu(2, 'Daily Data', '/download/quick-downloads', null, 'file_download', null, false, 24),
    // new Menu(3, 'MIS', '/download/mis', null, 'file_download', null, false, 24),

    new Menu(18,'Editor Notes','/editornotes',null, 'create', null, false, 0),

    new Menu(19, "Reports", '/reports', null, 'summarize', null, true, 0),
    new Menu(20, "MIS", '/reports/mis/1', null, 'insert_chart', null, false, 19),
    // new Menu(21, "Change in OI(Options)", '/reports/oi/1', null, 'insert_chart', null, false, 19),
    // new Menu(22, "Adding Most OI(Options)", '/reports/adding-most-oi/1', null, 'insert_chart', null, false, 19),
    // new Menu(23, "Rank", '/reports/rank/1', null, 'insert_chart', null, false, 19),
    new Menu(23, "Option Chain Plus", '/reports/daily-report/1', null, 'insert_chart', null, false, 19),
    // new Menu(24, "P&L(T-20 & B-20)", '/reports/pl/1', null, 'insert_chart', null, false, 19),
    new Menu(25, "Historical Data", '/reports/historical-data/1', null, 'insert_chart', null, false, 19),
    // new Menu(26, "MOM Report", '/reports/mom-report/1', null, 'insert_chart', null, false, 19),
    new Menu(27, "Highest Gainer OI", '/reports/highest-weekly-gainer-oi/1', null, 'insert_chart', null, false, 19),
    new Menu(28, "Future Reports", '/reports/future', null, 'insert_chart', null, true, 19),
     new Menu(29, "Future OI", '/reports/future/future-report/1', null, 'insert_chart', null, false, 28),
    new Menu(33, "Option Reports", '/reports/options', null, 'insert_chart', null, true, 19),
    // new Menu(34, "Option Report 1", '/reports/options/option-report1/1', null, 'insert_chart', null, false, 33),
    // new Menu(35, "Option Report 2", '/reports/options/option-report2/1', null, 'insert_chart', null, false, 33),
    new Menu(36, "Live Option Report", '/reports/options/option-report3/1', null, 'insert_chart', null, false, 33),
    new Menu(37, "Call Put Report", '/reports/options/call-put-report/1', null, 'insert_chart', null, false, 33),
    // new Menu(38, "Major Averages", '/reports/major-averages/1', null, 'insert_chart', null, false, 19),
    // new Menu(39, "MR Report", '/reports/mr-reports/1', null, 'insert_chart', null, false, 19),
    new Menu(40, "Market Future Report", '/reports/mf-report/1', null, 'insert_chart', null, false, 19),
    // new Menu(14, "Master Future Report", '/reports/master-future-report/1', null, 'insert_chart', null, false, 8),
    // new Menu(30, "Market Centemeter", '/reports/market-centemeter/1', null, 'insert_chart', null, false, 8),

    // new Menu(38, "Daily Data", '/daily-data', null, 'insert_chart', null,false, 0),
    // new Menu(38, "Daily Data Report", '/daily-data-report', null, 'insert_chart', null,false, 0),

    new Menu(41, "Users", '/user-details', null, 'admin_panel_settings', null, true, 0),
    new Menu(42, "Admin Users", '/user-details/admin-users', null, 'verified_user', null, false, 41),
    new Menu(43, "Registered Users", '/user-details/registered-users', null, 'supervisor_account', null, false, 41),



    // new Menu(8, "Testing Reports 1", '/backtesting-reports/testing-report1/1', null, 'insert_chart', null, false, 8),



    // new Menu(17,'Uploads','/uploads',null, 'cloud_upload', null, false, 0),
    // new Menu(16,'Finsparc Score','/finsparcscore',null, 'timeline', null, false, 0),
    
    // new Menu(29,'Call Put Values','/call-put-values',null, 'blur_on', null, false, 0),
   
    // new Menu(30,'Icons','/icons',null, 'donut_large', null, false, 0),


]

export const horizontalMenuItems = [ 
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Users', '/users', null, 'supervisor_account', null, false, 0), 
    new Menu (3, 'Bhav Copy', '/bhavcopy', null, 'bhavcopy', null, false, 0),
    new Menu (4, 'Lot Size', '/lotsize', null, 'lotsize', null, false, 0),
    new Menu(5, 'Hoildays List', '/holiday', null, 'event', null, false, 0),
    new Menu(6, 'Settlement Master', '/settlement', null, 'date_range', null, false, 0),

]