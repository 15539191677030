import { Component, OnInit } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { DatacallsService } from 'src/app/datacalls.service';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-score',
  templateUrl: './add-score.component.html',
  styleUrls: ['./add-score.component.scss'],
  providers:[DatacallsService]
})
export class AddScoreComponent implements OnInit {
scoreForm;
post;
id;
post1;
price_desc;
category;

min_range;
max_range;
interval;score;
// variable_days;
closing_avg_days;

  constructor(private FormBuilder:FormBuilder,private DatacallsService:DatacallsService,public snackBar:MatSnackBar,private router:Router,private ActivatedRoute:ActivatedRoute) { 
    this.scoreForm = this.FormBuilder.group({
      id:[0],
      // pricedesc:['',Validators.required],
      minrange:[''],
      maxrange:[''],
      maxscore:[''],
      interval:[''],  
      score:[''],
      observation_days:[''],  
      variable_days:[''],
      closing_avg_days:['']
    }) 
  }

  ngOnInit() {
    this.id=this.ActivatedRoute.snapshot.params['finsparc_score_id'];

    console.log('fetched id  retrive --=-=->',this.id)
  if(this.id!=null || this.id!=undefined){
    // this.DatacallsService.patch_viewFinsparc_score(this.id,'null','null').subscribe(posts=>{
    //   this.post1=posts.result;

    //   this.price_desc=posts.result[0].price_desc;
    //   this.category=posts.result[0].category;
    //   this.min_range=posts.result[0].min_range;
    //   this.max_range=posts.result[0].max_range;
    //   this.interval=posts.result[0].intval;
    //   this.score=posts.result[0].score;
    //   // this.variable_days=posts.result[0].variable_days;
    //   this.closing_avg_days=posts.result[0].closing_avg_days;

    //   console.log('about us data retrive --=-=->',this.post1)
    //   console.log('about us data retrive --key=-=->',posts.result[0].price_desc,'/n category',posts.result[0].category)

    //   if(posts.result[0].category=='rank'){
    //     this.scoreForm.get('minrange').disable();
    //     this.scoreForm.get('maxrange').disable();
    //     this.scoreForm.get('interval').disable();
    //     this.scoreForm.get('score').disable();
    //     this.scoreForm.get('closing_avg_days').disable();
    //     }
    //     else {
    //       this.scoreForm.get('minrange').enable();
    //     }


    //   this.scoreForm.patchValue({
    //       id:posts.result[0].finsparc_score_id,
    //       pricedesc:posts.result[0].price_desc,
    //       minrange:posts.result[0].min_range,
    //       maxrange:posts.result[0].max_range,
    //       maxscore:posts.result[0].max_score,
    //       interval:posts.result[0].intval,
    //       score:posts.result[0].score,
    //       observation_days:posts.result[0].observation_days,
    //       variable_days:posts.result[0].variable_days,
    //       closing_avg_days:posts.result[0].closing_avg_days      
    //   })
    
    // })
   }

  }

  onSubmit(){
    if(this.category=='rank'){
      var data={
        "id":this.scoreForm.value.id,
        "price_desc":this.price_desc,
        "min_range":this.min_range,
        "max_range":this.max_range,
        "max_score":this.scoreForm.value.maxscore,
        "interval":this.interval,
        "score": this.score,
        "observation_days":this.scoreForm.value.observation_days,
        "variable_days":this.scoreForm.value.variable_days,
        "closing_avg_days":this.closing_avg_days
      }  
    }
    else{
    var data={
      "id":this.scoreForm.value.id,
      "price_desc":this.price_desc,
      "min_range":this.scoreForm.value.minrange,
      "max_range":this.scoreForm.value.maxrange,
      "max_score":this.scoreForm.value.maxscore,
      "interval":this.scoreForm.value.interval,
      "score":this.scoreForm.value.score,
      "observation_days":this.scoreForm.value.observation_days,
      "variable_days":this.scoreForm.value.variable_days,
      "closing_avg_days": this.scoreForm.value.closing_avg_days
    }
  }
    console.log('data com---=-=>',data) 
      

    // this.DatacallsService.insert_finsparc_score(data).subscribe(posts=>{
    //   this.post=posts;
    //   console.log("post data -=-=>",this.post)
    //   this.snackBar.open('Data Insertion Done Successfully', '', {
    //     duration: 2000
    //   });
      
    //   this.router.navigate(['/housekeeping/finsparcscore']);

    // })
  }

  getDisabledValue(){
    if(this.category='rank')
    {
      return true;
    }

  }


}
