import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DatacallsService } from '../../datacalls.service';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-add-lotsize',
  templateUrl: './add-lotsize.component.html',
  styleUrls: ['./add-lotsize.component.scss'],
  providers:[DatacallsService]
})
export class AddLotsizeComponent implements OnInit {

  lotForm;
  post;
  posts;
  id;
  constructor(private FormBuilder: FormBuilder, private DatacallsService: DatacallsService, public snackBar: MatSnackBar, private router: Router,private ActivatedRoute:ActivatedRoute) { 

    this.lotForm = this.FormBuilder.group({
      id: [0],
      instrument: ['', Validators.required],
      symbol: ['', Validators.required],
      stock:  ['', Validators.required],
      lot_date: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.id=this.ActivatedRoute.snapshot.params['finsparc_lotsize_id'];
    console.log("patch value id -->",this.id);
    if(this.id!='undefined')
    {
    // this.DatacallsService.patchviewfinsparclotsize(this.id).subscribe(posts => {
    //   this.posts = posts.result
    //   // console.log("patch value data ",this.posts[0].finsparc_lotsize_id)

    //   this.lotForm.patchValue({
    //     id:this.posts[0].finsparc_lotsize_id,
    //     instrument:this.posts[0].instrument,
    //     symbol:this.posts[0].symbol,
    //     stock:this.posts[0].stock,
    //     lot_date:this.posts[0].lot_date

    //   })
    
    // });
  }

  }

onSubmit(){
var data={
            "id":this.lotForm.value.id,
            "instrument":this.lotForm.value.instrument,
            "symbol":this.lotForm.value.symbol,
            "stock":this.lotForm.value.stock,
            "lot_date":this.lotForm.value.lot_date
         }

    // this.DatacallsService.insert_finsparc_lotsize(data).subscribe(posts=>{
    //   this.post=posts;
    //   console.log('posts---> ',this.post);
    //   this.snackBar.open('Data Insertion Done Successfully', '', {
    //     duration: 2000
    //   });
      
    //   this.router.navigate(['/lotsize']);

    // })
  }

}
