import { Component, OnInit } from '@angular/core';
import { DatacallsService } from 'src/app/datacalls.service';
import { FormBuilder,Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-add-settlement',
  templateUrl: './add-settlement.component.html',
  styleUrls: ['./add-settlement.component.scss'],
  providers:[DatacallsService]
})
export class AddSettlementComponent implements OnInit {
  
  settlementForm;
  post;
  posts;
  id;
  constructor(private FormBuilder:FormBuilder,private DatacallsService:DatacallsService,public snackBar:MatSnackBar,private router:Router,private ActivatedRoute:ActivatedRoute ) { 
    this.settlementForm = this.FormBuilder.group({
      id: [0],
      type:  ['', Validators.required],
      date: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.id=this.ActivatedRoute.snapshot.params['finsparc_settlementdates_id'];
    console.log('fetch id-=-=>',this.id)

    // this.DatacallsService.viewfinsparcsettlement1(this.id).subscribe(posts=>{
    //   this.posts=posts.result;
    //   console.log('this fetched data-=-=-=>>>>',this.posts)
    //   // this.dataSource = new MatTableDataSource(this.posts);
    //   // this.dataSource.paginator = this.paginator;
    //   // console.log('this posts this.posts[0].cdate-=-=-=>>>>',this.posts[0].cdate) 
    //   this.settlementForm.patchValue({
    //     id:this.posts[0].finsparc_settlementdates_id,
    //     date:moment(this.posts[0].settlement_date).format('YYYY-MM-DD'),
    //     type:this.posts[0].settlement_type
    //   })
   
    //   console.log('the patch value data',this.posts[0].cdate);

    //   }); 

  }


  onSubmit(){
    var data={
                "id":this.settlementForm.value.id,
                "settlement_type":this.settlementForm.value.type,
                "settlement_date":moment(this.settlementForm.value.date).format('YYYY-MM-DD'),
                
             }
    
        // this.DatacallsService.insert_finsparcsettlement(data).subscribe(posts=>{
        //   this.post=posts;
        //   console.log('posts---> ',this.post);
        //   this.snackBar.open('Data Insertion Done Successfully', '', {
        //     duration: 2000
        //   });
          
        //   this.router.navigate(['/settlement']);
    
        // })
      }

}
