import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

@Injectable()
export class AuthguardGuard implements CanActivate {

 constructor(private router:Router){}

 canActivate(
 next: ActivatedRouteSnapshot,
 state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
 if(sessionStorage.getItem('currentuser')==undefined){
 this.router.navigate([''])
 return false;
       }
 else {
 return true;
       }
  }
}