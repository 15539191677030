import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  /* ------------------------ Get admin ---------------------- */
  getAdminUserData(json) {
    return this.http.post(environment.USER_API_URL + 'getAdminUsersData',json);
  }
  addUser(json) {
    return this.http.post(environment.USER_API_URL + 'addUser',json);
  }
  deleteUser(json) {
    return this.http.post(environment.USER_API_URL + 'deleteUser',json);
  }
  logout(json) {
    return this.http.post(environment.LOGIN_API_URL + 'logout',json);
  }

  addRole(json) {
    return this.http.post(environment.USER_API_URL + 'addRole',json);
  }

  getRoleData(json) {
    return this.http.post(environment.USER_API_URL + 'getRoleData',json);
  }

  deleteRole(json) {
    return this.http.post(environment.USER_API_URL + 'removeRoleData',json);
  }
  
  addRoleDataInRedis(json) {
    return this.http.post(environment.USER_API_URL + 'addRoleDataInRedis',json);
  }
  // allocateSymbol(json) {
  //   return this.http.post(environment.USER_API_URL + 'allocateSymbol',json);
  // }

}
