import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DatacallsService } from 'src/app/datacalls.service';
import { IndustriesService } from 'src/app/services/apis/industries.service';

@Component({
  selector: 'app-industry-symbol',
  templateUrl: './industry-symbol.component.html',
  styleUrls: ['./industry-symbol.component.scss'],
  providers:[DatacallsService]
})
export class IndustrySymbolComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'action'];
  dataSource;
  posts = [];
  industryForm;
  id;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  constructor(private DatacallsService: DatacallsService, private fb: FormBuilder, private snackBar: MatSnackBar, public dialog: MatDialog, private router: Router, private industriesService: IndustriesService, private ActivatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    
    this.id = this.ActivatedRoute.snapshot.params['id'];
    // console.log("patch value id -->", this.id);

    this.getIndustries(this.id);
  }

  getIndustries(id) {
    this.industriesService.getIndustrySymbol(id).subscribe((response: any) => {
      // console.log('resposne------------------>>>',response);

      if (response.statusCode == 200) {
        this.posts = response.result;
        this.setDataSource();
      }
    }, (error) => {
      console.log("API Error------------", error);
      this.setDataSource();
    })

  }

  unallocateSymbol(id){
    // console.log('inside delete industry-->>',id);
    let data={"ids":[id]}
    this.industriesService.unallocateSymbol(data).subscribe((res: any) => {
      if(res.statusCode==200){
        this.snackBar.open("Industry Unallocated", '', {
          duration: 2000
        });
        this.ngOnInit();
      }else{
        this.snackBar.open("Something Went Wrong ", '', {
          duration: 2000
        });
      }
    })
  }

  setDataSource() {
    // console.log("Function called to show the industries");
    this.dataSource = new MatTableDataSource(this.posts);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


}
