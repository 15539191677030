import { Component, OnInit } from '@angular/core';
import { DatacallsService } from 'src/app/datacalls.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-lot-change',
  templateUrl: './add-lot-change.component.html',
  styleUrls: ['./add-lot-change.component.scss'],
  providers: [DatacallsService]
})
export class AddLotChangeComponent implements OnInit {

  id;
  lotForm;
  post;
  posts;
change_date;
previous_lotsize;
  constructor(private ActivatedRoute: ActivatedRoute, private DatacallsService: DatacallsService, private FormBuilder: FormBuilder, private router: Router,public snakBar:MatSnackBar) {

    this.lotForm = this.FormBuilder.group({
      id: [0],
      symbol: [''], 
      adjust_factor: ['', Validators.required],
      change_type: ['', Validators.required],
      // lot_date: ['', Validators.required]
    })

  }

  ngOnInit() {
    this.id = this.ActivatedRoute.snapshot.params['id'];
    console.log("patch value id -->", this.id);

    if (this.id != 'undefined') {
      // this.DatacallsService.view_finsparc_lotchange(this.id).subscribe(posts => {
      //   this.posts = posts.result
      //   console.log('posts---=>',this.posts);
      //   // console.log("patch value data ",this.posts[0].finsparc_lotsize_id)
      //   this.change_date=this.posts[0].change_date;
      //   this.previous_lotsize=this.posts[0].previous_lotsize;

      //   this.lotForm.patchValue({
      //     id: this.posts[0].id,
      //     symbol: this.posts[0].symbol,
      //     adjust_factor: this.posts[0].adjust_factor,
      //     change_type: this.posts[0].change_type,
      //     // change_date: this.posts[0].change_date,
      //     // lot_date: this.posts[0].lot_date

      //   })

      // });
    }

  }

  
  onSubmit(){
    var data={
                "id":this.lotForm.value.id,
                "symbol":this.lotForm.value.symbol,
                "adjust_factor":this.lotForm.value.adjust_factor,
                "change_type":this.lotForm.value.change_type,
                "change_date":this.change_date,
                "previous_lotsize":this.previous_lotsize
             }
        console.log('this data -=-=-=>',data);
        // this.DatacallsService.Update_finsparc_lotchange(data).subscribe(posts=>{
        //   this.post=posts;
        //   console.log('posts---> ',this.post);
        //   this.snakBar.open('Data Insertion Done Successfully', '', {
        //     duration: 2000
        //   });
          
        //   this.router.navigate(['/housekeeping/lot-change']);
        // })
      }
}
